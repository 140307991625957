.page_controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 15px;
}

.page_control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 40px;
  font-size: 14px;
  font-weight: 200;
  background-color: rgba(0, 88, 220, 0.075);
  border-radius: 5px;
  color: rgba(0, 88, 220, 0.85);
  cursor: pointer;
  user-select: none;
  gap: 5px;
}

.page_control:hover {
  height: 40px;
  background-color: rgba(0, 88, 220, 0.15);
}
