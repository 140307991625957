/* .tab_layout {
  width: 100%;
  padding-top: 20px;
} */

.accountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: black;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  overflow-y: auto;

  overflow-y: auto;
}

.main_layout {
  padding: 24px;
  /* overflow-y: auto; */
}

.tab_layout {
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
  width: 100%;
  box-sizing: border-box;
}

.settingsTitle {
  margin: 0;
  color: black;
  font-weight: lighter;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  min-height: 60px;
}
/* background: rgba(255, 255, 255, 0.15); */

.tab {
  padding: 10px 15px;
  font-size: 22px;
  font-weight: 300;
  color: black;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}

.tab:not(:last-child) {
  margin-right: 15px;
}

.tab:hover {
  color: rgba(0, 88, 220, 0.5);
}

.active {
  color: rgba(0, 88, 220, 1);
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background: rgba(0, 88, 220, 1);
  transition: left 0.3s ease, width 0.3s ease;
}

.section {
  /* height: 300px; */
  box-sizing: border-box;
  /* overflow-y: auto; */
}

.profile_section_scroll {
  overflow-y: auto;
}

.top_section_title {
  text-align: start;
  font-size: 20px;
  font-weight: 200;
  margin: 0;
  margin-bottom: 20px;
}

.section_title {
  text-align: start;
  font-size: 25px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.proflie_top_information {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 20px;
  gap: 2px;
}

.proflie_top_information_text_1 {
  margin: 0;
  font-size: 20px;
  font-weight: 200;
}
.proflie_top_information_text_2 {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  opacity: 0.9;
}
.proflie_top_information_text_3 {
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.75;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 30px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: rgba(0, 88, 220, 0.35); */
  border: 1px solid rgba(0, 88, 220, 1);
  margin-left: 30px;
  border-radius: 10px;
  font-size: 14px;
  color: rgba(0, 88, 220, 1);
  cursor: pointer;
}

.profile_edit_section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px;
  height: 100px;
  margin-bottom: 25px;
}

.profile_section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;
  box-sizing: border-box;
  padding: 40px;
}

.edit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100px;
  height: 35px;
  font-weight: 200;
  font-size: 14px;
  border-radius: 50px;
}

.edit_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.save_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 25px;
  border: 1px solid rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  width: 100px;
  height: 35px;
  font-weight: 200;
  font-size: 14px;
  border-radius: 50px;
}

.save_button:hover {
  background-color: rgba(0, 88, 220, 0.2);
}

.profile_pic_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 180px;
  height: 35px;
  font-weight: 200;
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
}

.profile_pic_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.information_section_title {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  align-items: start;
  text-align: start;
  margin-bottom: 0px;
}

.payment_section_title {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  align-items: start;
  text-align: start;
  margin-bottom: 0px;
  margin-bottom: 20px;
}

.profile_fields {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.profile_picture_bg {
  width: 70px;
  height: 70px;
  border-radius: 40%;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.update_profile_picture:hover {
  color: white;
  background-color: rgba(0, 88, 220, 0.8);
}

.profile_picture_section_left {
  width: 49%;
}
.profile_picture_section_right {
  width: 49%;
}
.single_line_input {
  display: flex;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  width: 100%;
  height: 20px;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 200;
  text-align: start;
  white-space: nowrap;
}

.dropdown_container {
  width: 140%;
  position: relative;
  background-color: white;
}

.dropdown_item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 15px;
  text-align: start;
  font-size: 14px;
  font-weight: 300;
}

.dropdown_item:hover {
  background-color: rgba(0, 88, 220, 0.1);
}

.custom_dropdown {
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  z-index: 99;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
}

.single_line_input_editable {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  box-sizing: border-box;
  width: 80%;
  height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  border-radius: 5px;
  /* margin-bottom: 15px; */
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-weight: 300;
  opacity: 0.8;
  margin-top: 2.5px;
}

.input_title {
  margin: 0;
  text-align: start;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 100;
}

.section_title {
}

.profile_edit_field {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 25px;
  /* height: 100px; */
}

.profile_top_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.edit_profile_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background-color: rgba(0, 88, 220, 1);
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  user-select: none;
}

.edit_profile_button:hover {
  background-color: white;
  border: 1px solid rgba(0, 88, 220, 1);
  color: rgba(0, 88, 220, 1);
}

.save_profile_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background-color: rgba(0, 88, 220, 1);
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  user-select: none;
}

.save_profile_button:hover {
  background-color: rgba(0, 88, 220, 0.75);
}

.manage_subscription_button {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background-color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 0);
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.manage_subscription_button:hover {
  background-color: white;
  color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 1);
}

.plan_title {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 12.5px;
}

.plan_price {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12.5px;
}

.plan_renew {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}

/* CREDITCARD */
.credit_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 330px;
  min-width: 330px;
  height: 180px;
  /* background-image: linear-gradient(
    to right,
    rgba(65, 169, 246, 1),
    rgba(25, 118, 210, 0.8),
    rgba(13, 71, 161, 1)
  ); */

  /* background-image: linear-gradient(
    to right,
    rgba(0, 88, 220, 1),
    rgba(25, 118, 210, 0.8)
  ); */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}

.card_top_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  margin-bottom: 15px;
  /* height: 40px; */
}

.chip_image {
  height: 20px;
  object-fit: cover;
}

.card_brand_image {
  height: 25px;
  width: 60px;
  object-fit: cover;
}

.card_default {
  width: 70px;
  /* height: 40px; */
}

.card_middle {
  display: flex;
  width: 100%;
  height: 30px;
}

.card_number {
  margin: 0;
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.card_bottom_info {
  display: flex;
  align-items: row;
  justify-content: space-between;
  width: 100%;
}

.card_info_left {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.card_title {
  margin: 0;
  color: black;
  font-size: 14px;
  margin-bottom: 3px;
}

.card_value {
  margin: 0;
  color: black;
  font-size: 16px;
}

.card_info_right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.payment_section {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
  width: 100%;
}

.payment_plan_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;
}

.payment_section_button {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
  width: 100%;
}

.subscription_option_current {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 300px;
  height: 130px;
  background-color: rgba(0, 88, 220, 0.6);
  margin-right: 30px;
  border-radius: 5px;
  border: 1px solid rgba(0, 88, 220, 1);
  box-sizing: border-box;
  padding: 20px;
}

.check_icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background-color: rgba(0, 88, 220, 1);
  box-sizing: border-box;
  padding: 4px;
  z-index: 99999;
}

.subscription_option_upgrade {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 130px;
  background-color: rgba(170, 113, 190, 0.75);
  margin-right: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
}

.subscription_option_downgrade {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  height: 170px;
  background-color: rgba(0, 88, 220, 0.2);
  margin-right: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
}

.subscription_option_top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
}

.plan_type {
  margin: 0;
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.price_point {
  margin: 0;
  color: white;
}

.subscription_option_middle {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.plan_renews {
  margin: 0;
  color: white;
}

.subscription_option_bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
}

.current_plan_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  border-radius: 5px;

  /* background-color: rgba(255, 255, 255, 0.75);
  color: rgba(0, 88, 220, 1); */

  background-color: rgba(0, 88, 220, 1);
  color: white;
}

.upgrade_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  border: 1px solid rgba(170, 113, 190, 1);
  border-radius: 5px;

  background-color: white;
  color: rgba(170, 113, 190, 1);
  cursor: pointer;
}

.upgrade_button:hover {
  background-color: rgba(0, 88, 220, 1);
  color: white;
}

.learn_more {
  margin: 0;
  text-decoration: underline;
  color: white;
  cursor: pointer;
}

.bolder {
  font-weight: 600;
}

.security_options {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.security_option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.security_option_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.security_option_left {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
}

.security_option_title {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.security_option_subtitle {
  margin: 0;
  font-size: 14px;
  font-weight: 200;
}

.security_option_right {
  display: flex;
  flex-direction: row;
}

.edit_item_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100px;
  height: 35px;
  font-weight: 200;
  font-size: 14px;
  border-radius: 50px;
}

.change_password_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 150px;
  height: 35px;
  font-weight: 200;
  font-size: 14px;
  border-radius: 50px;
}

.delete_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  width: 120px;
  height: 35px;
  font-weight: 200;
  font-size: 14px;
  border-radius: 50px;
  color: rgba(200, 0, 0, 1);
}

.email_address {
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 100;
}

.plan_information {
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 30px;
}

.payment_information {
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 30px;
  margin-top: 30px;
}

.plan_information_top {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.plan_info {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 6px;
}

.plan_info_title {
  color: black;
  font-weight: 300;
  font-size: 16px;
}

.plan_info_value {
  color: black;
  font-weight: 400;
  font-size: 25px;
  opacity: 0.7;
}

.plan_info_value_small_span {
  color: black;
  font-weight: 300;
  font-size: 13px;
}

.plan_information_divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 30px;
}

.plan_information_bottom {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  gap: 60px;
}

.account_limit {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  width: 200px;
}

.account_limit_title {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}

.limit_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 200;
  width: 100%;
}

.upgrade_text_button {
  margin: 0;
  color: rgba(0, 88, 220, 1);
  cursor: pointer;
}

.upgrade_text_button:hover {
  color: rgba(0, 88, 220, 0.7);
}

.progress_line_background {
  width: 100%;
  height: 7px;
  background-color: rgba(0, 88, 220, 0.35);
  box-shadow: 0px 0px 15px 1.5px rgba(0, 88, 220, 0.2);
  border-radius: 10px;
}

.progress_line {
  height: 7px;
  background-color: rgba(0, 88, 220, 1);
  border-radius: 10px;
}

.payment_option {
  width: 100%;
}

.subscription_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
}

.plan_option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  min-width: 260px;
  /* height: 100px; */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 14px;
  margin: 3px;
  gap: 10px;
}

.plan_option_top_information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.plan_option_title {
  color: black;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.plan_option_price {
  color: black;
  font-size: 14px;
  opacity: 0.75;
  font-weight: 300;
  margin: 0;
  letter-spacing: 1px;
}

.plan_option_subtitle {
  color: black;
  font-size: 11px;
  font-weight: 200;
  margin: 0;
  text-align: start;
}

.plan_option_bottom_information {
  position: relative;
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.plan_option_status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
}

.plan_option_status_upgrade {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 30px;
  background-color: rgba(0, 88, 220, 0.15);
  border: 1px solid rgba(0, 88, 220, 1);
  color: rgba(0, 88, 220, 1);
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.plan_option_status_upgrade:hover {
  background-color: rgba(0, 88, 220, 0.25);
}

.card_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 3px;
}

.add_credit_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  min-width: 180px;
  height: 180px;
  gap: 10px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.75);
}

.add_credit_card_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
}

.add_credit_card_button:hover {
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
}

.manage_payment_button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
}

.manage_payment_button:hover {
  background-color: rgba(0, 88, 220, 0.15);
}

.plan_data_icons {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

/* .data_icon {
  background-color: rgba(0, 88, 220, 0.15);
} */

.data_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  /* border-radius: 5px; */
}

.youtube_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  box-sizing: border-box;
  padding: 1px;
  border-radius: 4px;
  /* border-radius: 5px; */
}
