.landing_navbar {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 90px;
  z-index: 1000;
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.logo_icon {
  height: 80px;
}

.menu_items {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.top_left_box {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  height: 100%;
  cursor: pointer;
}

.top_left_title {
  color: white;
  margin: 0;
  font-size: 30px;
  margin: 5px;
}
.top_left_title:hover {
  color: #0058dc;
}
.top_left_underline {
  height: 1.5px;
  width: 175px;
  background: #0058dc;
}

.logo {
  margin-top: 10px;
  height: 65px;
  object-fit: cover;
  cursor: pointer;
}

.menu_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
}

.menu_item {
  color: white;
  margin-left: 30px;
  cursor: pointer;
  width: 60px;
}

.menu_item:hover {
  color: #0058dc;
}

.active_menu_item {
  color: #0058dc;
  margin-left: 30px;
  cursor: pointer;
  font-weight: 600;
  width: 60px;
}

.landing_page_layout {
  width: 100vw;
  height: 100vh;
}

.button_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
}

.login_button {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 20px;
}

.login_text {
  font-size: 18px;
  color: white;
  margin-right: 10px;
}

.login_text:hover {
  opacity: 0.7;
  cursor: pointer;
}

.signup_button {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 130px;
  height: 40px;
  cursor: pointer;

  color: #0058dc;
  background-color: white;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0);
}

.signup_button:hover {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 1);
  color: white;
}

.header_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0058dc;
  width: 100%;
  height: 45px;
}

.header_text {
  color: white;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

@media (max-width: 768px) {
  .header_text {
    font-size: 12px;
  }
}
