.main_layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  overflow-y: auto;
  padding: 0 70px;
}

.main_layout_no_padding {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  padding: 0;
  width: 100%;
}

.page_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 55px;
  background-color: white;
  box-sizing: border-box;
  margin-bottom: 15px;
  gap: 40px;
}

.page_title {
  display: flex;
  color: black;
  font-size: 18px;
  align-items: start;
  font-weight: 200;
  margin: 0;
  padding-top: 50px;
  opacity: 0.7;
}

.line {
  flex: 1;
  height: 2px;
  background-color: rgba(0, 88, 220, 0.2);
  border-radius: 5px;
}

.line_active {
  background-color: rgba(0, 88, 220, 0.75);
}

.page_layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0px;
}

.step_tracker {
  /* width: 300px;
  height: 300px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); */

  width: 220px;
}

.content {
  flex: 1;
  padding: 0px 100px;
  padding-right: 65px;
  overflow-y: auto;
  margin-bottom: 50px;
}

/* .content {
  flex: 1;
  margin: 0px 100px;
  background-color: rgba(0, 88, 220, 0.05);
  box-shadow: 0px 0px 1.5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 30px;
  box-sizing: border-box;
} */

.step_box {
  width: 100%;
  height: 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  gap: 12.5px;
}

.step_box:hover {
  background-color: rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0px 1.5px rgba(0, 0, 0, 0.15);
}

.step_box_current {
  width: 100%;
  height: 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: rgba(0, 88, 220, 0.075);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.step_title {
  text-align: start;
  margin: 0;
  opacity: 0.75;
}

.stage_completion {
  margin: 0;
  color: rgba(0, 0, 0, 0.55);
  font-size: 35px;
  text-align: start;
  margin-bottom: 25px;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  font-weight: 200;
  letter-spacing: 6px;
}

.stage_completion_blue {
  color: rgba(0, 88, 220, 0.8);
}

.bottom_controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
  margin-right: 100px;
  gap: 20px;
  margin-top: 35px;
  padding-top: 35px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.back_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  cursor: pointer;
}

.back_button:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.back_button_disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  opacity: 0.4;
}

.next_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 42px;
  background-color: rgba(0, 88, 220, 0.2);
  color: rgba(0, 88, 220, 0.9);
  border-radius: 3px;
  cursor: pointer;
}

.next_button:hover {
  background-color: rgba(0, 88, 220, 0.3);
}

.next_button_disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 42px;
  background-color: rgba(0, 88, 220, 0.2);
  color: rgba(0, 88, 220, 0.9);
  border-radius: 3px;
  cursor: pointer;
  opacity: 0.3;
}

.launch_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  background-color: rgba(0, 88, 220, 0.2);
  border: 1px solid rgba(0, 88, 220, 0.8);
  color: rgba(0, 88, 220, 0.9);
  border-radius: 3px;
  cursor: pointer;
}

.launch_button:hover {
  background-color: rgba(0, 88, 220, 0.3);
}
