.main_layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  overflow-y: auto;
  padding: 50px;
}

.page_header {
  display: flex;
  flex-direction: row;
  position: relative;
  /* position: fixed;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: 300px;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 24px; */
  /* height: 180px; */
  height: 160px;
  /* margin-bottom: 40px; */
}

.update_campaign_button:hover {
  background-color: white;
  color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 1);
}

.page_header_left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.page_header_right {
  /* width: 30%; */
}

.campaign_title {
  color: black;
  font-size: 35px;
  align-items: start;
  font-weight: 200;
  margin: 0;
  margin-left: 15px;
}

.campaign_title_back {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
}

.back_button {
  cursor: pointer;
}

.back_button:hover {
  opacity: 0.4;
}

.percent_complete_text {
  display: flex;
  color: rgba(0, 88, 220, 1);
  font-size: 16px;
  align-items: start;
  font-weight: 200;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 10px;
}

.campaign_description {
  display: flex;
  color: black;
  font-size: 14px;
  align-items: start;
  font-weight: 300;
  margin: 0;
  text-align: start;
  width: 80%;
}

.progress_line_background {
  width: 80%;
  height: 1px;
  background-color: rgba(0, 88, 220, 0.3);
  box-shadow: 0px 0px 15px 2px rgba(0, 88, 220, 0.25);
  border-radius: 10px;
}

.progress_line {
  height: 1px;
  background-color: rgba(0, 88, 220, 1);
  border-radius: 10px;
  width: 0%; /* Initialize with 0% */
  transition: width 2s ease-out;
}

.percent_complete_text_span {
  width: 40px;
  margin-right: 5px;
}

.campaign_stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* height: 100px; 
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); */
}

.stat_title {
  color: black;
  font-size: 14px;

  font-weight: 200;
  margin: 0;
  margin-bottom: 10px;
}

.stat_value {
  color: black;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.campaign_contact_list {
  width: 100%;
  flex: 1;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.campaign_contact_cell_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.campaign_contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.campaign_contact_image {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 25px;
}

.campaign_cell_first {
  width: calc(170px + 26px + 25px);
  text-align: start;
  font-size: 13px;
}

.campaign_cell_header {
  width: 170px;
  text-align: start;
  font-size: 13px;
  opacity: 0.7;
}

.campaign_cell {
  width: 170px;
  text-align: start;
  font-size: 13px;
  overflow: hidden;
  opacity: 0.7;
  font-weight: 300;
}

.email_cell_header {
  width: 220px;
  text-align: start;
  font-size: 13px;
  overflow: hidden;
}

.email_cell {
  width: 220px;
  text-align: start;
  font-size: 13px;
  overflow: hidden;
  opacity: 0.7;
  font-weight: 300;
}

.campaign_controls {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.right_controls {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: -25px;
}

.export_button {
  font-size: 16px;
  opacity: 0.7;
  font-weight: 300;
}

.add_contacts_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 88, 220, 1);
  gap: 6px;
  border: 1px solid rgba(0, 88, 220, 0.75);
  border-radius: 50px;
  padding: 6px 18px;
}

.search_container {
  display: flex;
  align-items: center;
}

.search_container_input {
  border: none;
  outline: none;
  margin-left: 10px;
  width: 250px;
}

.contact_status_sent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  border-radius: 3px;
  border: 1px solid rgba(0, 131, 10, 0.55);
  box-sizing: border-box;
  /* padding: 0px 10px; */
  width: 90px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 131, 10, 1);
  background-color: rgba(0, 131, 10, 0.1);
}

.contact_status_pending {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  border-radius: 3px;
  border: 1px solid rgba(253, 218, 13, 1);
  box-sizing: border-box;
  /* padding: 0px 10px; */
  width: 90px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(139, 128, 0, 1);
  background-color: rgba(253, 218, 13, 0.1);
}

.status_active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 85px; */
  height: 37px;
  border-radius: 3px;
  border: 1px solid rgba(0, 131, 10, 1);
  gap: 2.5px;
  box-sizing: border-box;
  padding: 0px 20px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 131, 10, 1);
  gap: 15px;
  background-color: rgba(0, 131, 10, 0.1);
}

.status_paused {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 85px; */
  height: 37px;
  border-radius: 3px;
  border: 1px solid rgba(200, 0, 0, 1);
  gap: 2.5px;
  box-sizing: border-box;
  padding: 0px 20px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(200, 0, 0, 1);
  gap: 15px;
  background-color: rgba(200, 0, 0, 0.1);
}

.status_completed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 85px; */
  height: 37px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 1);
  gap: 2.5px;
  box-sizing: border-box;
  padding: 0px 20px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  gap: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.status_dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.control_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  width: 37px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  opacity: 0.75;
  cursor: pointer;
}

.control_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.top_controls {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 5px;
  right: 0;
}

.update_campaign_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  background-color: rgba(0, 88, 220, 1);
  color: white;
  font-size: 15px;
  font-weight: 300;
  border-radius: 3.5px;
  cursor: pointer;
  border: 1px solid rgba(0, 88, 220, 0);
  margin-left: 10px;
  box-sizing: border-box;
}

.campaign_tile_top_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.reply_status_true {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 131, 10, 0.15);
  height: 27px;
  width: 27px;
  border-radius: 50%;
}

.reply_status_false {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.085); */
  height: 27px;
  width: 27px;
  border-radius: 50%;
}
