.home_components {
  width: 100vw;
  height: 100vh;
  background-color: #0058dc;
}
.pricing_page {
  /* margin-top: 200px; */
  padding-top: 160px;
  position: relative;
}
