.fullscreen_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}

.fullscreen_modal.open {
  opacity: 1;
  pointer-events: auto;
}

.modal_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 60%;
  /* height: 550px; */
  /* background-color: rgba(250, 250, 250, 1); */
  background-color: white;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 50px;
  gap: 35px;
}

.link_icon {
  height: 30px;
  height: 30px;
}



.modal_header {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.header_title {
  font-weight: 400;
  font-size: 22px;
  color: black;
  margin: 0;
  margin-bottom: 7px;
}

.header_subtitle {
  font-weight: 100;
  font-size: 16px;
  text-align: start;
  color: black;
  margin: 0;
}

.modal_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.email_option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  height: 220px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 5px;
  border-radius: 3px;
}

.email_option:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.email_option_selected {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  background-color: rgba(0, 88, 220, 0.09);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  height: 220px;
  box-sizing: border-box;
  cursor: pointer;           
  gap: 5px;
  border-radius: 3px;
}

.email_icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.email_title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.email_subtitle {
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.modal_bottom {
  display: flex;
  justify-content: end;
  width: 100%;
}

.modal_disclaimer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: 25px;
  border-radius: 3px;
}

.disclaimer_text {
  margin: 0;
  font-size: 14px;
  text-align: start;
  color: rgba(0, 0, 0, 0.7);
  flex: 1;
  margin-left: 20px;
}

.link_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 40px;
  border: 1px solid rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  border-radius: 3px;
  gap: 5px;
  cursor: pointer;
}

.link_button:hover {
  background-color: rgba(0, 88, 220, 0.2);
  color: rgba(0, 88, 220, 1);
}

.link_button_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 40px;
  border: 1px solid rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  border-radius: 3px;
  gap: 5px;
  opacity: 0.5;
}

.link_icon {
  width: 22px;
  height: 22px;
}

.modal_image {
  width: 150px;
  height: 150px;
}

.modal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60px;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.modal_button:hover {
  background-color: white;
  border: 1px solid white;
  color: rgba(0, 88, 220, 1);
}
