.bullet_chart {
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  box-sizing: border-box;
  /* justify-content: start; */
  width: 90%;
  height: 400px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  overflow-y: auto;
}

.bullet_chart_small {
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  box-sizing: border-box;
  /* justify-content: start; */
  width: 90%;
  height: 300px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  overflow-y: auto;
}

.interestItem {
  margin-bottom: 20px;
}

.interestItemText {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.interestName {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
}

.interestValue {
  color: gray;
}

.horizontalBox {
  margin-top: 7.5px;
  height: 17px;
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 10px;
}

.filler {
  height: 100%;
  background-color: rgba(0, 88, 220, 1);
  border-radius: 10px;
}

.boxed_info_layout {
  width: 100%;

  /* height: 300px; */
}

.boxed_info {
  width: 100%;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  flex-direction: row;
  align-content: start;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  border-radius: 5px;
}

.item_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0px 10px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
}

.item_info {
  font-size: 15px;
  font-weight: 300;
}

.account_info_list {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.account_pfp {
  width: 65px;
  height: 65px;
  margin-right: 15px;
  border-radius: 50%;
  object-fit: cover;
}

.account_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account_text {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.account_username {
  margin: 0;
  color: black;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 18px;
}

.account_followers {
  margin: 0;
  color: black;
  opacity: 0.75;
  font-size: 15px;
}

.section_title {
  display: flex;
  align-self: start;
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}

.section_subtitle {
  display: flex;
  align-self: start;
  font-size: 16px;
  color: black;
  font-weight: 400;
  opacity: 0.75;
  margin: 0;
  margin-bottom: 20px;
}
