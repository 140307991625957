.campaign_stage {
  flex: 1;
}

.question_layout {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.question_title {
  color: black;
  font-size: 17px;
  margin: 0;
  opacity: 0.6;
  margin-bottom: 12px;
}

.spacer {
  height: 25px;
}

.top_titles {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  width: 100%;
}

.question_single_line {
  width: 100%;
  height: 40px;
  /* border: 1px solid rgba(0, 88, 220, 0.5); */
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;

  box-sizing: border-box;
  padding: 15px;

  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}

.question_box {
  width: 100%;
  height: 135px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  resize: none;

  box-sizing: border-box;
  padding: 15px;

  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}

.account_targeting {
  color: black;
  font-size: 25px;
  font-weight: 200;
  margin: 0;
  opacity: 0.85;
  text-align: start;
  margin-bottom: 20px;
}

.account_contacts {
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  opacity: 0.65;
  text-align: start;
  margin-bottom: 20px;
}

.header_title {
  font-weight: 400;
  font-size: 18px;
  color: black;
  margin: 0;
  margin-bottom: 15px;
}

.header_subtitle {
  font-weight: 100;
  font-size: 16px;
  text-align: start;
  color: black;
  margin: 0;
}

.message_list_layout {
  display: flex;
  flex: 1;
  box-sizing: border-box;
  position: relative;
}

.message_list_tracker {
  position: absolute;
  left: -40px;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 88, 220, 1);
}

.message_list {
  flex: 1;
}

.modal_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: space-between; */
  width: 100%;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 30px;
}

.delete_button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 20px;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(200, 0, 0, 0.1);
  cursor: pointer;
}

.delete_button:hover {
  background-color: rgba(200, 0, 0, 0.15);
}

.email_option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  height: 220px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 5px;
  border-radius: 3px;
}

.email_option:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.email_option_selected {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  background-color: rgba(0, 88, 220, 0.09);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  height: 220px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 5px;
  border-radius: 3px;
}

.email_icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.email_title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.email_subtitle {
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.modal_bottom {
  display: flex;
  justify-content: end;
  width: 100%;
}

.link_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 40px;
  border: 1px solid rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  border-radius: 3px;
  gap: 5px;
  cursor: pointer;
}

.link_button:hover {
  background-color: rgba(0, 88, 220, 0.2);
  color: rgba(0, 88, 220, 1);
}

.link_button_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 40px;
  border: 1px solid rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  border-radius: 3px;
  gap: 5px;
  opacity: 0.5;
}

.link_icon {
  width: 22px;
  height: 22px;
}

.modal_image {
  width: 150px;
  height: 150px;
}

.modal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60px;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.modal_button:hover {
  background-color: white;
  border: 1px solid white;
  color: rgba(0, 88, 220, 1);
}

.email_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
}

.email_info_title {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 1.5px; */
}

.email_info_content {
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  border: none;
  outline: none;
  width: 300px;
  margin-left: 10px;
  margin-top: 2.25px;
}

.section_line_divider {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  /* margin-top: 15px;
    margin-bottom: 15px; */
}

.email_display {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  margin-left: 10px;

  background-color: rgba(0, 88, 220, 0.125);
  box-sizing: border-box;
  padding: 7.5px 10px;
  border-radius: 3px;
  gap: 10px;
  cursor: pointer;
}

.email_from {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  margin-left: 10px;

  background-color: rgba(0, 88, 220, 0.125);
  box-sizing: border-box;
  padding: 5.5px 10px;
  border-radius: 3px;
  gap: 10px;
  cursor: pointer;
}

.email_from:hover {
  background-color: rgba(0, 88, 220, 0.25);
}

.email_body {
  margin-top: 15px;
  width: 100%;
  height: 150px;
}

.email_body_area {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  outline: none;
  resize: none;
}

.down_icon {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;

  width: 100%;
  top: calc(100% + 10px); /* 5px below the parent element */
  left: 0;
}

.dropdown_item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  height: 25px;
}

.dropdown_item:hover {
  background-color: #f1f1f1;
}

.rotate_180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

.contacts_header {
  display: flex;
  width: 100%;
  height: 60px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.contacts_tile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.remove_contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-right: 15px;
}

.tile_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.remove_contact:hover {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
}

.campaign_contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  max-height: 375px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 2px;
}

.basic_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 100%;
  margin-left: 15px;
}

.cell_width {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  height: 100%;
  margin-left: 15px;
}

.creator_profile_picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.creator_display_names {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.creator_name {
  margin: 0;
  color: black;
  font-size: 14px;
}

.creator_username {
  margin: 0;
  color: black;
  opacity: 0.7;
  font-size: 13px;
}

.header_cell_titles {
  margin: 0;
  font-weight: 300;
}

.header_cell_values {
  margin: 0;
  font-weight: 300;
}

.empty_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 230px;
  gap: 10px;
}

.empty_text {
  margin: 0;
  font-weight: 200;
}

.add_controls {
  width: 100%;
  margin-top: 35px;
}

.add_email {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 88, 220, 1);
  height: 35px;
  width: 200px;
  /* background-color: rgba(0, 88, 220, 0.1); */
  border: 1px solid rgba(0, 88, 220, 0.6);
  border-radius: 3px;
  cursor: pointer;
}

.add_email:hover {
  color: rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  border: 1px solid rgba(0, 88, 220, 0.6);
}

.delay_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.delay_time {
  margin: 0;
  margin-left: 50px;
  margin-right: 50px;
  font-weight: 300;
  opacity: 0.8;
}

.line_div {
  flex: 1;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.campaign_overview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  min-height: 300px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 30px;
  /* margin-bottom: 30px; */
}

.campaign_overview_title {
  margin: 0;
  color: black;
  opacity: 1;
  font-size: 28px;
  text-align: start;
  font-weight: 200;
  margin-bottom: 6px;
}

.campaign_overview_description {
  margin: 0;
  color: black;
  opacity: 0.6;
  font-size: 14px;
  text-align: start;
}

.campaign_overview_divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 30px;
}

.campaign_overview_section_title {
  margin: 0;
  color: black;
  opacity: 0.8;
  font-size: 18px;
  text-align: start;
  margin-bottom: 10px;
}

.campaign_overview_section_text {
  margin: 0;
  color: black;
  opacity: 0.5;
  font-size: 16px;
  text-align: start;
}

.active_times {
  margin-top: 40px;
  display: flex;
  gap: 25px;
}

.section_title {
  color: black;
  font-size: 17px;
  margin: 0;
  opacity: 0.7;
  margin-bottom: 18px;
  text-align: start;
}

.active_days {
  margin-bottom: 30px;
}

.spacer_sm {
    height: 5px;
}