.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: calc(100vh);
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

.page_title {
  display: flex;
  color: black;
  font-size: 30px;
  align-items: start;
  font-weight: 300;
  margin: 0;
  padding-bottom: 0px;
  margin-bottom: 20px;
}

.creator_list_controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.creator_list_right_controls {
  display: flex;
  align-items: center;
  position: relative;
}

.creator_list_left_controls {
  display: flex;
  align-items: center;
}

.creator_found_title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.creator_selected_text {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.sort_section {
  display: flex;
  align-items: center;
  margin-left: 25px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.sort_section:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.sort_icon {
  width: 18px;
  height: 18px;
  object-fit: cover;
  margin-right: 5px;
  user-select: none;
}

.flipped {
  transform: rotate(180deg);
}

.sort_text {
  margin: 0;
  font-weight: 300;
  user-select: none;
}

.chosen_sort_text {
  font-weight: 500;
  user-select: none;
}

.dropdown_arrow_sort {
  width: 20px;
  height: 20px;
  user-select: none;
  margin-left: 10px;
}

.export_section {
  display: flex;
  align-items: center;
  margin-left: 40px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;

  opacity: 0.3;
  /* margin-right: 10px; */
}

.export_section_active {
  display: flex;
  align-items: center;
  margin-left: 40px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
  /* margin-right: 10px; */
}

.export_section_active:hover {
  background-color: rgba(0, 0, 0, 0.065);
}

.locked_icon {
  width: 15px;
  height: 15px;
  object-fit: cover;
  margin-right: 7.5px;
  opacity: 0.7;
}

.export_text {
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.sortDropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  width: 180px;
}

.sortDropdownItem {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  height: 45px;
  box-sizing: border-box;
  user-select: none;
  /* Add hover effects, etc. */
}

.sortDropdownItemClicked {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  height: 45px;
  box-sizing: border-box;
  user-select: none;
  background-color: rgba(0, 88, 220, 0.1);
}

.sortDropdownItem:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.connect_button_section {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: end;
}

.connect_button_all {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;

  color: var(--color-scheme-hex);
  font-size: 16px;
  user-select: none;
}
/* 
  .connect_button:hover {
    background-color: var(--color-scheme-hex);
    color: white;
  } */

.connect_all_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid var(--color-scheme-hex);
  color: var(--color-scheme-hex);
  font-size: 14px;
  user-select: none;
  margin-left: 20px;
}

.connect_all_button:hover {
  background-color: var(--color-scheme-hex);
  color: white;
}

.arrow_icon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  transform: rotate(180deg);
}

.field_titles {
  font-size: 15px;
  font-weight: 300;
}

.field_titles_selected {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-scheme-hex);
}

.arrow_icon_hover {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  transform: rotate(180deg);
  opacity: 0.7;
}

.element {
  background-color: var(--color-scheme-hex);
}

.add_contact_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  /* width: 90px;
    height: 30px; */
  padding: 7px 12px;
  border-radius: 3px;
  border: 1px solid var(--color-scheme-hex);
  color: var(--color-scheme-hex);
  font-size: 14px;
  user-select: none;
  cursor: pointer;
}

.add_contact_button:hover {
  background-color: var(--color-scheme-hex);
  color: white;
}

.connect_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  /* width: 90px;
    height: 30px; */
  padding: 7px 12px;
  border-radius: 3px;
  border: 1px solid var(--color-scheme-hex);
  color: var(--color-scheme-hex);
  font-size: 14px;
  user-select: none;
  cursor: pointer;
}

.connect_button:hover {
  background-color: var(--color-scheme-hex);
  color: white;
}

.add_to_contacts_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  /* width: 90px;
    height: 30px; */
  padding: 7px 12px;
  border-radius: 3px;
  border: 1px solid var(--color-scheme-hex);
  color: var(--color-scheme-hex);
  font-size: 14px;
  user-select: none;
  cursor: pointer;
}

.add_to_contacts_button:hover {
  background-color: var(--color-scheme-hex);
  color: white;
}

.creator_list_fields {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 50px;
  margin-top: 20px;
  padding-right: 15px;
}

.creator_list {
  /* min-height: 1400px; */
  margin-bottom: 20px;
  /* padding-right: 15px; */
}

.creator_tile_margin {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-right: 15px;
}

.creator_tile_layout {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 90px;
  /* padding-left: 15px; */

  /* cursor: pointer; */
}

/* .creator_tile_layout:hover {
    background-color: rgba(0, 88, 220, 0.1);
  } */

.creator_tile_layout_checked {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 90px;
  /* padding-left: 15px; */
  /* padding-right: 15px; */
  /* cursor: pointer; */
  background-color: rgba(0, 88, 220, 0.1);
}

.creator_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
}

.account_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.creator_profile_picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.creator_display_names {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.creator_name {
  margin: 0;
  color: black;
}

.creator_username {
  margin: 0;
  color: black;
  opacity: 0.7;
}

.checkbox_field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 60px;
  margin-left: 15px;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid rgba(0, 0, 0, 0.75);
  /* border: 1px solid rgba(0, 88, 220, 0.75); */
}

.creator_username {
  margin: 0;
  color: black;
  opacity: 0.7;
}

.tile_section {
  display: flex;
  height: 100%;
  width: 17.5%;
  align-items: center;
  cursor: pointer;
}

.tile_date_section {
  display: flex;
  height: 100%;
  width: 22.5%;
  align-items: center;
  cursor: pointer;
}

.tile_title_section {
  display: flex;
  height: 100%;
  width: 17.5%;
  align-items: center;
  cursor: pointer;
}

.tile_title_date_section {
  display: flex;
  height: 100%;
  width: 22.5%;
  align-items: center;
  cursor: pointer;
}

.connect_button_section {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: end;
  gap: 15px;
}

.rescind_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid red;
  color: red;
  font-size: 14px;
  user-select: none;
}

.rescind_button:hover {
  background-color: red;
  color: white;
}

/* .connect_button_text {
    color: var(--color-scheme-hex);
    font-size: 14px;
    margin: 0;
  } */

.export {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 88, 220, 0.8);
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
}

.creator_stat {
  color: black;
  opacity: 0.75;
  font-weight: 300;
}

.ui_checkbox {
  --primary-color: #1677ff;
  --secondary-color: #fff;
  --primary-hover-color: #4096ff;
  /* checkbox */
  --checkbox-diameter: 18px;
  --checkbox-border-radius: 2px;
  --checkbox-border-color: rgba(0, 0, 0, 0.5);
  --checkbox-border-width: 1px;
  --checkbox-border-style: solid;
  /* checkmark */
  --checkmark-size: 1.2;
}

.ui_checkbox,
.ui_checkbox *,
.ui_checkbox *::before,
.ui_checkbox *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui_checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--checkbox-diameter);
  height: var(--checkbox-diameter);
  border-radius: var(--checkbox-border-radius);
  background: var(--secondary-color);
  border: var(--checkbox-border-width) var(--checkbox-border-style)
    var(--checkbox-border-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

.ui_checkbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5)
    var(--primary-color);
  box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  border-radius: inherit;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ui_checkbox::before {
  top: 40%;
  left: 50%;
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  border-right: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}

/* actions */

.ui_checkbox:hover {
  border-color: var(--primary-color);
}

.ui_checkbox:checked {
  background: var(--primary-color);
  border-color: transparent;
}

.ui_checkbox:checked::before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(45deg)
    scale(var(--checkmark-size));
  -ms-transform: translate(-50%, -50%) rotate(45deg)
    scale(var(--checkmark-size));
  transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ui_checkbox:active:not(:checked)::after {
  -webkit-transition: none;
  -o-transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: none;
  opacity: 1;
}

.no_contacts_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  gap: 20px;
}

.empty_text {
  margin: 0;
  font-weight: 200;
  font-size: 16px;
}

.search_contacts_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background-color: rgba(0, 88, 220, 0.07);
  border: 1px solid rgba(0, 88, 220, 0.7);
  color: rgba(0, 88, 220, 1);
  height: 45px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
}

.search_contacts_button:hover {
  background-color: rgba(0, 88, 220, 0.15);
}
