.root {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 100%;
  background-color: silver;
  height: 100vh;
  position: fixed;
  top: 0;
}

.drawer {
  width: 250px;
  border: none !important;
}

.drawerPaper {
  border: none !important;
  width: 275px;
  background: white !important;
  overflow-y: auto !important;
  height: 100vh;
  /* border-right: 1px solid rgba(0, 0, 0, 0.5) !important; */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
  z-index: 99999 !important;
}

.content {
  /* flex-grow: 1; */
  background-color: white;
  margin-left: 275px;
  height: calc(100vh);
}

.container {
  max-width: calc(100% - 275px) !important;
}
.iconWrapper {
  display: flex;
  gap: 10px;
}
.iconWrapper svg {
  cursor: pointer;
}
.iconWrapper svg:hover path {
  fill: white !important;
}
@media (min-width: 1800px) {
  .container {
    max-width: calc(100% - 275px) !important;
  }
}

@media (max-width: 600px) {
  .content {
    padding: 8px !important;
  }
  .container {
    padding: 8px !important;
  }
}

.sidebar_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
}

.sidebar_icon_image {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.listItemText {
  font-size: 16px;
  font-family: "Poppins";
  color: white;
}

.sidebar_bottom_layout {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.sidebar_bottom_options {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* background-color: #2EBA55; */
}

/* ######### */
.navbar_sidebar_extension {
  display: flex;
  justify-content: center;
  all: center;
  /* background-color: red; */
  width: 100%;
  height: 60px;
}

.sidebar_logo {
  height: 60px;
  object-fit: cover;
}

.sidebar_tab_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  user-select: none;
  /* margin-bottom: 10px; */
}

.active_list_item_content {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 80%;
  background-color: white;
  height: 100%;
  color: rgba(255, 255, 255, 0.5); /* Set default text color */

  user-select: none;
}

.list_item_content {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  /* border-radius: 5px; */
  color: rgba(255, 255, 255, 0.5); /* Set default text color */
  user-select: none;
  border-right: 2px solid rgba(0, 88, 220, 0);
}

.list_item_content:hover {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
  background-color: rgba(0, 88, 220, 0.05);
}

.notification_dot_orange {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  background-color: rgba(211, 84, 0, 1);
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
}

.notification_dot_blue {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  background-color: rgba(0, 88, 220, 1);
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 300;
}

/* .list_item_content:hover,
.list_item_content:hover .active_list_item_content, */
.active_list_item_content {
  background-color: rgba(0, 88, 220, 0.1);
  /* background-color: rgba(255, 255, 255, 0.7); */
  color: #003d82; /* Set the text color to blue */
  user-select: none;
  border-right: 2px solid rgba(0, 88, 220, 0.6);
}

.active_sidebar_text {
  user-select: none;
  color: rgba(0, 61, 130, 1);
  margin: 0;
  margin-left: 15px;
  font-size: 14px;
}

.hovered_sidebar_text {
  user-select: none;
  color: rgba(0, 61, 130, 0.7);
  margin: 0;
  margin-left: 15px;
  font-size: 14px;
}

.sidebar_text {
  /* color: white; */
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  margin-left: 15px;
  font-size: 14px;
}

.share_refferal_promotion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 45px;
  background-color: rgba(0, 88, 220, 0.1);
  border: 1px solid rgba(0, 88, 220, 0.8);
  margin-bottom: 25px;
  border-radius: 5px;
  cursor: pointer;
}

.share_refferal_promotion:hover {
  background-color: rgba(0, 88, 220, 0.2);
}

.developer_mode_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  border-radius: 7.5px;
  color: white;
  opacity: 0.8;
  cursor: pointer;
}

.developer_mode_button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  cursor: pointer;
}

.share_refferal_title {
  color: white;
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
}

.share_refferal_subtitle {
  color: rgba(0, 88, 220, 1);
  font-weight: 200;
  font-size: 16px;
  margin: 0;
}

.social_icon {
  object-fit: cover;
  width: 25px;
  height: 25px;
  object-fit: cover;
  opacity: 0.6;
}

.coming_soon {
  position: absolute;
  right: 10px;
  padding: 5px;
  font-size: 10px;
  border: 1px solid #ffa500;
  color: #ffa500;
  border-radius: 5px;
  margin-left: 15px;
}
