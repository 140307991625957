.home_components {
  width: 100vw;
  /* height: 100vh; */
}

.landing_image {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home_header_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.page_title {
  color: #0058dc;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 50px;
}
.join_waitlist {
  color: #0058dc;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 15px;
}

.sidebar {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Semi-transparent white background */
  border-right: 2px solid #0058dc;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.email_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;

  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

.input {
  background-color: transparent;
  margin-left: 10px;
  border: none;
  outline: none;
}

.input::placeholder {
  color: black;
  opacity: 0.2;
  font-weight: 400;
}

.join_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
  color: white;
}

.join_button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.join_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
  color: #0058dc;
  opacity: 0.5;
}

.join_button_text {
  font-size: 15px;
  font-weight: 400;
}

@media (max-width: 1100px) {
  .sidebar {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: white;
    border-right: 2px solid #0058dc;
    box-sizing: border-box;
    padding: 30px;
  }
}

.note {
  color: #0058dc;
  opacity: 0.5;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}

.logo {
  width: 100px;
  object-fit: cover;
}

/* Texting this so we can chat about it today instead of putting it into Adobe XD where it might be missed:

What if we put a “Which best describes you?” box on both our Join Waitlist and Download Lead Magnet pages?

We could give these below options. I like this because it’s not being nosey and asking them how much money they make, but it gives us an idea of how much they make 

- I am a new financial representative (less than 1 year)
- I have been in financial services for a few years, but I’m still struggling a bit 
- I have been in financial services for a few years, and I’m doing okay. Looking for additional help 
- I am an established Financial Advisor who is looking for tools/systems to implement to make my business work even better */
