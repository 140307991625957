.page_layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  flex-grow: 1;
}

.sidebar {
  min-width: 200px;
  border: none;
  background: white;
  overflow-y: auto;
  height: 100vh;

  background-color: rgba(0, 61, 130, 0.1);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  /* border-right: 1px solid rgba(0, 0, 0, 0.5); */
}

.menu_item {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  padding: 15px;
  color: black;
  cursor: pointer;
}

.menu_item:hover {
  background-color: rgba(0, 61, 130, 0.04);
  color: rgba(0, 61, 130, 0.7);
}

.menu_item_selected {
  display: flex;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;

  height: 60px;
  background-color: rgba(0, 61, 130, 0.1);
  color: rgba(0, 61, 130, 1);
  user-select: none;
}
