.main_layout {
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
  height: calc(100vh - 60px);
  width: calc(100vw - 275px);
  overflow: hidden;
  overflow-y: auto;
}

.full_width {
  width: 100%;
}

.scroll_header {
  position: fixed; /* This will make the header stay at the top */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 60px; /* Position it at the top of the viewport */
  left: 0; /* Position it at the left of the viewport */
  width: calc(100vw - 250px);
  left: 250px;
  height: 80px;
  z-index: 5;
  /* This ensures the header stays on top of other elements */
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 40px;
  box-sizing: border-box;
  padding-left: 60px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  padding: 40px;
}

.back_icon {
  width: 32px;
  height: 32px;
  object-fit: cover;
  opacity: 0.8;
  cursor: pointer;
}

.profile_controls {
  display: flex;
  flex-direction: row;
}

.gray_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.07);
  color: black;
  margin-right: 15px;
  border-radius: 5px;
}

.blue_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  background-color: rgba(0, 88, 220, 0.9);
  color: white;
  border-radius: 5px;
  padding-bottom: 0;
}

.profile_basic_info {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* height: 200px; */
  /* width: 100%; */
  padding: 40px;
  padding-top: 0;
}

.profile_picture {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.profile_info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.profile_name {
  color: black;
  font-size: 33px;
  font-weight: 600;
  margin: 0;
}

.profile_username {
  color: black;
  font-size: 20px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 13px;
}

.profile_bio {
  color: black;
  font-size: 17px;
  font-weight: 300;
  margin: 0;
  width: 350px;
  text-align: start;
}

.gray_button {
  width: 100px;
}

.info_section {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 10px;
  /* width: 100vw; */
}

.profile_sections {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
  min-height: 80px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.profile_section_option {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}

.profile_section_option_selected {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: rgba(0, 88, 220, 0.125);
  color: rgba(0, 88, 220, 1);
  height: 80%;
}

.profile_section_option:hover {
  background-color: rgba(0, 88, 220, 0.075);
  color: rgba(0, 88, 220, 1);
  height: 80%;
}

.active {
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  height: 80%;
}

.icon_background {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.07);
  margin-right: 10px;
}

.top_info_title {
  color: black;
  margin: 0;
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 14px;
}

.top_info_subtitle {
  color: black;
  margin: 0;
  font-weight: 500;
}

.info_icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section_title {
  display: flex;
  margin: 0;
  font-size: 26px;
  align-self: start;
  margin-left: 40px;
  margin-top: 40px;
  font-weight: 600;
  margin-bottom: 30px;
}

.loading_screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 100%;
}
