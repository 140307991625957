.tabSpace {
  font-size: 12px !important;
  margin-right: 10px !important;
}
.loader {
  text-align: center !important;
  margin-top: 2% !important;
  width: 100%;
}
.loader > img {
  width: 30% !important;
  height: 35% !important;
}
.soundPackBasic {
  display: flex;
  gap: 10px;
  align-items: center;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10%;
}
.textField {
  width: 100%;
  border: 1px solid #afafaf !important;
  color: #fff !important;
  border-radius: 5px;
  transition: border 0.5s;
}
.textField input {
  color: #afafaf;
}
.textField fieldset {
  display: none;
}
.textField:hover,
.textField:focus-within {
  border-color: #2ebb55 !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999999;
}

.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #afafaf !important;
}
.labelContent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #afafaf !important;
}
.saveBtn {
  background: #2ebb55 !important;
  border: 1px solid #2ebb55 !important;
  border-radius: 5px !important;
  color: #fff !important;
  padding: 2% 5% !important;
  text-transform: uppercase !important;
}
.saveBtn:hover {
  background: transparent !important;
}
.cancelBtn {
  background: transparent;
  border: 1px solid #afafaf !important;
  border-radius: 5px !important;
  color: #fff !important;
  padding: 2% 5% !important;
  text-transform: uppercase !important;
}
.cancelBtn:hover {
  background: #afafaf !important;
}
.musicianName {
  align-self: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #afafaf;
  word-break: break-all;
}
.modalPaper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  width: 400px;
  /* min-height: 300px; */
  overflow-y: auto;
  border-radius: 8px;
  padding: 20px;
}

.delete_icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.modal_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.counterweight {
}

.close_icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.close_icon:hover {
  opacity: 0.75;
}

.popup_text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  opacity: 0.7;
  height: 35px;
}

.modal_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.popup_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.cancel_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 45px;
  border: 5px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.cancel_button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.delete_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 45px;
  border: 5px;
  background-color: rgba(200, 0, 0, 1);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  border: 1px solid rgba(0, 61, 130, 0);
}

.delete_button:hover {
  background-color: rgba(200, 0, 0, 0.3);
  color: rgba(200, 0, 0, 1);
  border: 1px solid rgba(200, 0, 0, 0.5);
}

/* .cancel_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px 17.5px;
  background-color: rgba(0, 0, 0, 0.4);
  margin-right: 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: 1px solid rgba(0, 61, 130, 0);
} */

/* .save_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  font-size: 14px;
  box-sizing: border-box;
  padding: 10px 17.5px;
  background-color: rgba(0, 61, 130, 1);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: 1px solid rgba(0, 61, 130, 0);
}

.save_button:hover {
  background-color: rgba(0, 61, 130, 0.3);
  color: rgba(0, 61, 130, 1);
  border: 1px solid rgba(0, 61, 130, 0.5);
} */

.fullscreen_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}

.fullscreen_modal.open {
  opacity: 1;
  pointer-events: auto;
}
