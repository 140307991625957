.main_layout {
  display: flex;
  flex-direction: column;
  padding: 34px;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.feedbackBox {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 61, 130, 0.75);
  box-sizing: border-box;
  padding: 15px;
  overflow-y: auto;
}

.response_text {
  text-align: start;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.send_button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 200px; */
  height: 45px;
  border-radius: 5px;
  /* background-color: #003d82; */
  border: 1px solid #003d82;
  color: #003d82;
  align-self: end;
  cursor: pointer;
  margin-right: 15px;
  padding: 0 15px;
}

.send_button:hover {
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.loading_box {
  height: 60%;
}

.loading {
  background-color: rgba(0, 61, 130, 1);
  color: white;
}
