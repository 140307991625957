.home_components {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.logo_icon {
  position: absolute;
  height: 60px;
  top: 10px;
  left: 30px;
  /* margin-bottom: 30px; */
}

.landing_image {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home_header_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home_header_content_right {
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* .page_title {
  color: #0058dc;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 50px;
} */
.page_title {
  color: black;
  font-weight: 200;
  font-size: 32px;
  margin: 0;
  margin-bottom: 6px;
  opacity: 0.75;
}

.page_subtitle {
  color: black;
  font-weight: 200;
  font-size: 18px;
  margin: 0;
  margin-bottom: 30px;
  opacity: 0.75;
}

.sidebar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 100%;

  box-sizing: border-box;
  padding: 60px 150px;
}

.sidebar_payment {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 50%;
  /* height: 100%; */
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 60px 90px;
  /* padding-top: 30px; */
  /* padding-bottom: 0; */
}

.sidebar_choose_plan {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 50%;
  /* height: 100%; */
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 60px 90px;
  /* padding-top: 30px; sidebar*/
  /* padding-bottom: 0; */
}



.right_side_sign_in {
  position: relative;
  background-color: rgba(0, 88, 220, 1);
  flex: 1;
}

.right_side_sign_in::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35); /* Dark overlay */
  backdrop-filter: blur(0px); /* Blur effect */
  z-index: 1; /* Ensure it's above the image */
}

/* .blue_background {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0; 
} */

.name_inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.whole_input_field {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
}

.spacer {
  min-height: 15px;
}

.blue_background {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview_ss {
  position: absolute;
  left: 150px;
  bottom: -10px;

  height: 400px;
  object-fit: cover;
  object-position: top left;
  border-radius: 10px;
  z-index: 99999;

  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
}

.preview_text {
  top: 100px;
  left: 150px;
  right: 50px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 9999;
  gap: 15px;
}

.preview_title_text {
  color: white;
  opacity: 1;
  font-size: 50px;
  font-weight: 400;
  text-align: start;
  margin: 0;
}

.preview_subtitle_text {
  color: white;
  opacity: 1;
  font-size: 25px;
  font-weight: 300;
  text-align: start;
  margin: 0;
}

.sidebar_right {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Semi-transparent white background */
  border-left: 2px solid #0058dc;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.sidebar_subscription {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: #0058dc;
  border-left: 2px solid white;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.two_column_inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.half_input_object {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 48%;
}

.email_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
  user-select: none;
  border-radius: 5px;
}

.sign_up_input {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
  user-select: none;
  border-radius: 5px;
}

.paragraph_input {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  /* background: rgba(0, 0, 0, 0.05); */
  /* margin-bottom: 15px; */
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.input {
  background-color: transparent;
  margin-left: 10px;
  flex-grow: 1;
  border: none;
  outline: none;
  margin-right: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.textarea_input {
  background-color: transparent;
  /* margin-left: 10px; */
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  /* margin-right: 20px; */
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.input::placeholder {
  color: black;
  opacity: 0.2;
  font-weight: 400;
}

.dropdown_input_option {
  width: 100%;
}

.dropdown_input {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
}

.dropdown_input_element {
  border: none;
  outline: none;
  flex-grow: 1;
  margin-left: 15px;
}

/* Add this to your CSS file, or create a new CSS file if needed */
.dropdown_options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #ccc;
  width: 50%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  background-color: #fff;
}

.dropdown_item {
  display: flex;
  justify-content: start;
  /* height: 55px; */
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.dropdown_item:hover {
  background-color: rgba(0, 88, 220, 0.2);
}

.page_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: rgba(0, 88, 220, 0.8);
  color: white;

  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
}

.page_button:hover {
  background-color: rgba(0, 88, 220, 1);
}

.page_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  /* background: rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 88, 220, 0.2);
  color: #0058dc;
  opacity: 0.5;
  margin-bottom: 10px;
  border-radius: 3px;
}

.join_button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.join_button_text {
  font-size: 15px;
  font-weight: 400;
}

.clickable_text {
  color: #0058dc;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .sidebar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 30px;
  }
}

.logo {
  width: 100px;
  object-fit: cover;
}

.login_input_titles {
  /* height: 60px; */
  margin: 0;
  margin-bottom: 8px;
  opacity: 0.4;
}

.error_text {
  color: rgba(200, 0, 0, 0.8);
  margin: 0;
  margin-bottom: 12px;
}

.auth_input_icon {
  margin-left: 20px;
  width: 16px;
  height: 16px;
  opacity: 0.6;
  object-fit: cover;
}

.subscription_plans {
  width: 100%;
}

.profile_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 30px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: rgba(0, 88, 220, 0.35); */
  border: 1px solid rgba(0, 88, 220, 0.6);
  margin-left: 30px;
  border-radius: 10px;
  font-size: 14px;
  color: rgba(0, 88, 220, 1);
  cursor: pointer;
}

.profile_edit_section {
  display: flex;
}

.profile_picture_bg {
  width: 75px;
  height: 75px;
  border-radius: 40%;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.update_profile_picture:hover {
  color: white;
  background-color: rgba(0, 88, 220, 0.8);
}

.prompt_texts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.note {
  color: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.forgot_password {
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: 300;
  text-align: end;
  cursor: pointer;
  align-self: end;
  margin: 0;
  margin-bottom: 30px;
}

.forgot_password:hover {
  color: rgba(0, 0, 0, 0.7);
}

.page_break_line {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 10px;
  margin: 10px 0px;
}

.line_half {
  flex: 1;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.or_text {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 200;
  opacity: 0.5;
}

.auth_options_3rd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.sign_in_with_google {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  /* background: rgba(0, 0, 0, 0.2); */
  /* background-color: rgba(0, 88, 220, 0.2); */
  color: black;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  gap: 20px;
  font-size: 12px;
  cursor: pointer;
}

.sign_in_with_google:hover {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}

.google_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
}

.login_with_auth_text {
  font-size: 13px;
  font-weight: 300;
}

.password_strength {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  /* height: 125px; */
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  gap: 10px;
}

.password_strength_title {
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: black;
  text-align: start;
  opacity: 0.8;
}

.requirements {
  width: 100%;
  font-size: 14px;
  font-weight: 200;
  margin: 0;
  color: black;
  text-align: start;
  opacity: 0.8;
}

.password_strength_indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.password_strength_line {
  flex: 1;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
}

.password_strength_validations {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  /* gap: 10px; */
  width: 100%;
}

.password_requirement {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  gap: 15px;
}

.password_requirement_text {
  margin: 0;
  font-size: 12px;
  font-weight: 200;
  /* opacity: 0.1; */
}

.cancel_bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.075);
}

.previous_step {
  color: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  user-select: none;
  cursor: pointer;
  margin: 0;
  /* text-decoration: underline; */
  margin-top: 5px;
}

.previous_step:hover {
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  cursor: pointer;
}

.bottom_controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.special_control_next {
  color: rgba(0, 88, 220, 0.75);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  user-select: none;
  cursor: pointer;
  margin: 0;
  margin-top: 5px;
}

.special_control_next:hover {
  color: rgba(0, 88, 220, 0.95);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  cursor: pointer;
}

.product_plans {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.product_plan_layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 88, 220, 0);
  width: 100%;
  border-radius: 3.5px;
  cursor: pointer;
  gap: 7px;
  position: relative;
}

.product_plan_layout:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.selected_product_plan_layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(0, 88, 220, 0.5);
  border: 1px solid rgba(0, 88, 220, 0.6);
  width: 100%;
  border-radius: 3.5px;
  cursor: pointer;
  gap: 7px;
  position: relative;
}

.plan_information {
  width: 100%;
}

.plan_title {
  font-size: 18px;
  font-weight: 400;
  opacity: 0.8;
  text-align: start;
  margin: 0;
  margin-bottom: 3px;
  width: 100%;
}

.frequency_options {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 17px;
}

.frequency_option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.frequency_select {
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.frequency_text {
  margin: 0;
  opacity: 0.65;
  font-size: 14px;
  font-weight: 300;
}

.plan_select_circle {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.plan_select_circle_selected {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(0, 88, 220, 0.5);
}

.plan_select_inner_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(0, 88, 220, 1);
}

.data_sources {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 15px;
  gap: 5px;
}

.data_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  /* border-radius: 5px; */
}

.youtube_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  box-sizing: border-box;
  padding: 1px;
  border-radius: 4px;
  /* border-radius: 5px; */
}

.plan_title_price {
  /* margin-left: 10px; */
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.54px;
  text-align: start;
}

.plan_page_title {
  color: black;
  font-weight: 200;
  font-size: 32px;
  margin: 0;
  margin-bottom: 12px;
  opacity: 0.75;
}

.radio_inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  /* background-color: #eee; */
  box-sizing: border-box;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.3);
  padding: 0.25rem;
  width: 300px;
  font-size: 14px;
}

.radio_inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio_inputs .radio input {
  display: none;
}

.radio_inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
}

.radio_inputs .radio input:checked + .name {
  background-color: rgba(0, 88, 220, 0.04);
  color: rgba(0, 88, 220, 1);
  font-weight: 600;
}

.checkout_form {
  width: 100%;
}

.pay_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  background-color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 0);
  box-sizing: border-box;
  color: white;
  margin-top: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
}

.pay_button:hover {
  border: 1px solid rgba(0, 88, 220, 1);
  color: rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.4);
}

.loader_layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.dropdown_selection {
  position: absolute;
  top: calc(100% + 15px);
  left: -1px;
  width: 100%;
  max-height: 200px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  z-index: 999999;
  overflow: auto;
  border-radius: 5px;
}

.dropdown_selection_options {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.dropdown_selection_options:hover {
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 0.8);
}

.down_icon {
  width: 20px;
  height: 20px;
  opacity: 0.75;
  user-select: none;
  margin-right: 15px;
}

.rotate_180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

.test_controls {
  position: absolute;
  top: 20px;
  right: calc(50% + 20px);
}

.feature_limits {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.marketing_feature_text {
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  text-align: start;
  color: rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  padding: 5px 10px;
  border-radius: 3px;
}

.marketing_feature_box_text {
  margin: 0;
  margin-top: 7px;
  font-size: 14px;
  font-weight: 300;
  text-align: start;
  /* color: rgba(0, 88, 220, 1); */
  color: rgba(0, 0, 0, 1);
  /* background-color: rgba(0, 88, 220, 0.1);
  padding: 5px 10px; */
  border-radius: 3px;
}

/* .marketing_features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
} */
