.section_layout {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 40px;
  padding-top: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

.sub_section_layout {
  width: 100%;
}

.sub_section_title {
  display: flex;
  align-self: start;
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}

.sub_section_subtitle {
  display: flex;
  align-self: start;
  font-size: 16px;
  color: black;
  font-weight: 400;
  opacity: 0.75;
  margin: 0;
  margin-bottom: 20px;
}

.chart_section_title {
  display: flex;
  align-self: start;
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
}

.performance_layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px; /* Adjust as necessary for spacing between items */
  width: 100%;
  margin-top: 20px;
}

.performance_tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
}

.performance_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.stat_value {
  margin: 0;
  color: black;
  font-size: 25px;
  opacity: 0.8;
  margin-bottom: 5px;
}

.stat_title {
  margin: 0;
  color: black;
  font-size: 18px;
  font-weight: 300;
  text-align: start;
}

/* .performance_tile:hover {
  border: 1px solid rgba(0, 88, 220, 1);
} */

.top_content_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  /* width: calc(100vw - 250px); */
  overflow-x: auto;
  margin-top: 12.5px;
  /* height: 410px; */
}

.top_content_tile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  min-width: 300px;
  /* height: 400px; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); */
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  /* overflow-y: hidden; */
  margin-right: 10px;
}

.stat_top_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7.5px;
}

.stat_icon_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.stat_icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  opacity: 0.7;
}

.top_content_top_info {
  height: 60px;
  width: 270px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content_post_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile_image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
}

.platform_image {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.top_content_image {
  width: 270px;
  height: 270px;
  object-fit: cover;
  border-radius: 5px;
}

.top_content_bottom_info {
  height: 100px;
  width: 270px;
}

.top_content_caption {
  text-align: start;
  height: 60px;
  overflow: hidden;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.top_content_engagement {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top_content_engagement_numbers {
  margin: 0;
  margin-right: 10px;
}

.top_content_icons {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.sub_section_chart_layout {
  display: flex;
  flex-direction: row;
  align-self: start;
  font-size: 20px;
  color: black;
  font-weight: 400;
  width: 100%;
}

.sub_section_layout_full {
  display: flex;
  flex-direction: column;
  align-self: start;
  font-size: 20px;
  color: black;
  font-weight: 400;
  width: 100%;
}

.interests_layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* creates 2 equal-width columns */
  gap: 20px; /* optional: adjust if you want spacing between boxes */
}

.half_screen_layout {
  width: 50%;
}

.full_screen_layout {
  display: flex;
  justify-content: start;
  width: 100%;
}

.section_divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.section_divider_line {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
}

/* -------------------------- AUDIENCE LOCATION -------------------------- */

/* -------------------------- AUDIENCE LOCATION END -------------------------- */

.slider_menu {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  background-color: rgba(0, 88, 220, 0.1);
  width: 300px;
  border-radius: 50px;
  margin-bottom: 20px;
}

.slider_menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 85%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: rgba(0, 88, 220, 1);
  cursor: pointer;
  border-radius: 50px;
}

.active_slider_menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 85%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: rgba(0, 88, 220, 1);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  border-radius: 50px;
}

.slider_menu_item:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.chart_layout {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.legend_layout {
  display: flex;
  flex-direction: row;
}

.legend_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-right: 55px;
}

.legend_item_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.legend_value {
  margin: 0;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
}

.legend_title {
  margin: 0;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 300;
}
