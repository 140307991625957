.fullscreen_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}

.fullscreen_modal.open {
  opacity: 1;
  pointer-events: auto;
}

.modal_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 500px;
  /* background-color: rgba(250, 250, 250, 1); */
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 70, 1),
    rgba(0, 0, 100, 1),
    rgba(0, 0, 130, 1)
  );

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 50px;
}

.header_title {
  font-weight: 300;
  font-size: 20px;
  color: white;
  margin: 0;
}

.modal_image {
  width: 150px;
  height: 150px;
}

.modal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60px;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.modal_button:hover {
  background-color: white;
  border: 1px solid white;
  color: rgba(0, 88, 220, 1);
}
