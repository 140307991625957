.fullscreen_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}

.fullscreen_modal.open {
  opacity: 1;
  pointer-events: auto;
}

.modal_body {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 70%;
  /* height: 550px; */
  /* background-color: rgba(250, 250, 250, 1); */
  background-color: white;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px;
  gap: 35px;
}

.close_icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  opacity: 0.7;
}

.close_icon:hover {
  opacity: 1;
}

.link_icon {
  height: 30px;
  height: 30px;
}

.modal_header {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.header_title {
  font-weight: 400;
  font-size: 22px;
  color: black;
  margin: 0;
  margin-bottom: 25px;
}

.header_subtitle {
  font-weight: 100;
  font-size: 16px;
  text-align: start;
  color: black;
  margin: 0;
}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  /* justify-content: space-between; */
  width: 100%;
  height: 70vh;
}

.email_option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  height: 220px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 5px;
  border-radius: 3px;
}

.email_option:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.email_option_selected {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  background-color: rgba(0, 88, 220, 0.09);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  height: 220px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 5px;
  border-radius: 3px;
}

.email_icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.email_title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.email_subtitle {
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.modal_bottom {
  display: flex;
  justify-content: end;
  width: 100%;
}

.modal_disclaimer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: 25px;
  border-radius: 3px;
}

.disclaimer_text {
  margin: 0;
  font-size: 14px;
  text-align: start;
  color: rgba(0, 0, 0, 0.7);
  flex: 1;
  margin-left: 20px;
}

.link_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 40px;
  border: 1px solid rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  border-radius: 3px;
  gap: 5px;
  cursor: pointer;
}

.link_button:hover {
  background-color: rgba(0, 88, 220, 0.2);
  color: rgba(0, 88, 220, 1);
}

.link_button_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 40px;
  border: 1px solid rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.1);
  color: rgba(0, 88, 220, 1);
  border-radius: 3px;
  gap: 5px;
  opacity: 0.5;
}

.link_icon {
  width: 22px;
  height: 22px;
}

.modal_image {
  width: 150px;
  height: 150px;
}

.modal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60px;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.modal_button:hover {
  background-color: white;
  border: 1px solid white;
  color: rgba(0, 88, 220, 1);
}

.email_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
}

.email_info_title {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 1.5px; */
}

.email_info_content {
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  border: none;
  outline: none;
  width: 300px;
  margin-left: 10px;
  margin-top: 2.25px;
}

.section_line_divider {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  /* margin-top: 15px;
  margin-bottom: 15px; */
}

.email_display {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  margin-left: 10px;

  background-color: rgba(0, 88, 220, 0.125);
  box-sizing: border-box;
  padding: 7.5px 10px;
  border-radius: 3px;
  gap: 10px;
  cursor: pointer;
}

.email_from {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  margin-left: 10px;

  background-color: rgba(0, 88, 220, 0.125);
  box-sizing: border-box;
  padding: 7.5px 10px;
  border-radius: 3px;
  gap: 10px;
  cursor: pointer;
}

.email_from:hover {
  background-color: rgba(0, 88, 220, 0.25);
}

.email_body {
  margin-top: 15px;
  width: 100%;
  height: 200px;
}

.email_body_area {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  outline: none;
  resize: none;
}

.down_icon {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;

  width: 100%;
  top: calc(100% + 10px); /* 5px below the parent element */
  left: 0;
}

.dropdown_item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  height: 25px;
}

.dropdown_item:hover {
  background-color: #f1f1f1;
}

.rotate_180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

.add_contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 135px;
  background-color: rgba(0, 88, 220, 1);
  color: white;
  border-radius: 3px;
}
