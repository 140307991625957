.home_components {
  width: 100vw;
  overflow: hidden;
  /* height: 100vh; */
}

.landing_image {
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  object-fit: cover;
  object-position: bottom center;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
}

.navbar_box {
  /* background: rgba(255, 255, 255, 1); */
}

.home_header_content {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);

  z-index: 1;

  /* border-radius: 10px; */
}

.landing_header {
  /* background: rgba(255, 255, 255, 0.5); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  margin-left: 50px;
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.title_copywriting {
  color: white;
  font-size: 35px;
  text-align: start;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.subtitle_copywriting {
  color: white;
  font-size: 20px;
  text-align: start;
  margin: 0;
  margin-bottom: 10px;
}

.summary_copywriting_box {
  width: 70%;
}

.summary_copywriting {
  color: #0058dc;
  font-size: 22px;
  margin: 0;
  margin: 30px;
}

.buttons {
  display: flex;
  flex-direction: row;
  /* width: 25%; */
  height: 75px;
  justify-content: space-between;
  margin-bottom: 50px;
}

.trial_button_text {
  /* color: #E50019; */
}
.trial_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 100%;
  color: white;
  /* background-color: #E50019; */
  border: 2px solid white;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 30px;
}

.trial_button:hover {
  color: #0058dc;
  background-color: white;
  cursor: pointer;
}

.demo_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
}
.demo_button_text {
  color: #0058dc;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -5px;
  width: 100%;
  height: 400px;
  background-color: #0058dc;
  /* clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%); */
  z-index: 9999; /* Optional: this may be necessary if you want this element to be on top of others */
  margin-bottom: 30px;
}

.banner_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 65%;
}

.banner_title {
  color: white;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

.banner_content_text {
  color: white;
  margin: 0;
  font-size: 30px;
  font-weight: 200;
  margin-top: 30px;
  margin-bottom: 40px;
}

.banner_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  counter-reset: #e50019;
  color: #e50019;
  border-radius: 5px;
  width: 150px;
  height: 55px;
  cursor: pointer;
}

.banner_button:hover {
  background-color: #e50019;
  color: white;
}

.section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  /* height: 550px; */
}

.easier_cards {
  margin-top: 75px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-items: center;
  align-items: center;
  width: 90%;
  /* margin-left: 1000px;
  margin-right: 1000px; */
}

.easy_card {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 290px;
  height: 320px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
  box-sizing: border-box;
  border-radius: 10px;
  /* padding: 15px; */
  /* padding: 50px; */
}

/* .easy_card:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3) !important;
  border: 1px solid #e50019;
} */

.value_prop_circle {
  margin-top: 5px;
  background-color: #e50019;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important; */
}

.value_prop_image {
  width: 100%;
  height: 45%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.value_prop_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 15px;
}

.value_prop_title {
  margin: 0;
  font-size: 21px;
  margin-top: 0px;
  margin-bottom: 3px;
  color: black;
  /* margin-bottom: 7.5px; */
}

.value_prop_subtitle {
  margin: 0;
  margin-top: 5px;
  font-size: 17px;
  /* text-align: start; */
  flex-grow: 1;
  font-weight: 300;
  text-align: left;
}

.banner_two {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* top: 100px; */
  margin-top: 100px;
  margin-bottom: 30px;
  width: 100%;
  /* height: 550px; */
  background-image: linear-gradient(to top right, #001f41, #e50019);
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  z-index: 9999; /* Optional: this may be necessary if you want this element to be on top of others */
}

.banner_content_left {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 60%;
}

.banner_title_medium {
  margin: 0;
  font-size: 22px;
  text-align: start;
  color: white;
  align-self: start;
  font-weight: 600;
  margin-top: 100px;
}

.banner_title_small {
  margin: 0;
  font-size: 22px;
  text-align: start;
  color: white;
  align-self: start;
  font-weight: 600;
}

.banner_paragraph_top {
  margin: 0;
  font-size: 16px;
  text-align: start;
  color: white;
  margin-bottom: 40px;
  margin-top: 5px;
  font-weight: 200;
}

.banner_paragraph {
  margin: 0;
  font-size: 16px;
  text-align: start;
  color: white;
  margin-bottom: 100px;
  margin-top: 5px;
  font-weight: 200;
}

.expierence_section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  margin-top: 50px;
}

.expierence_title {
  color: #e50019;
  margin: 0;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 20px;
  width: 100%;
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
}

.expierence_logo {
  width: 13vw;
  object-fit: cover;
  margin-bottom: 20px;
  /* margin-left: 55px;
  margin-right: 55px; */
}

.expierence_logo_sm {
  height: 4vw;
}

.letter_section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
}
.letter_content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 300px; */
  width: 90%;
  background-image: linear-gradient(to right, #001f41, #e50019);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 50px;
}

.letter_textbox {
  /* display: flex;
  justify-content: center;
  align-items: ; */
}

.letter_text {
  font-size: 15px;
  color: white;
  text-align: start;
  cursor: pointer;
}

.hyperlink {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.hyperlink:hover {
  opacity: 0.7;
}

.skeptical_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #ededed; */
  width: 100%;
  /* height: 500px; */
  padding-left: 200px;
  padding-right: 200px;
  box-sizing: border-box;
}

.skeptical_title {
  margin-top: 10px;
  font-size: 30px;
  color: #e50019;
  font-weight: 200;
  margin: 0;
  margin-top: 50px;
}

.skeptical_text {
  font-size: 17px;
  color: #e50019;
  text-align: start;
  font-weight: 300;
  margin-bottom: 70px;
}

@media (max-width: 1200px) {
  .easier_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
    justify-items: center;
    align-items: center;
    width: 75%;
  }
}

@media (max-width: 768px) {
  .main_copywriting {
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .easier_cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 80px;
    justify-items: center;
    align-items: center;
    width: 75%;
  }
  .banner_title {
    color: white;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }

  .banner_content_text {
    color: white;
    margin: 0;
    font-size: 18px;
    font-weight: 200;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .replyassist_will {
    font-size: 13px;
    margin-right: 5px;
    /* opacity: 0; */
  }

  .word {
    font-size: 13px;
    /* opacity: 0; */
    /* padding-bottom: -20px; */
  }

  .title_copywriting {
    font-size: 25px;
  }

  .subtitle_copywriting {
    font-size: 13px;
  }

  .skeptical_section {
    padding-left: 50px;
    padding-right: 50px;
  }
  .banner {
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .banner_two {
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
  }
  .trial_button_text {
    /* color: #E50019; */
    font-size: 18px;
  }
  .trial_button {
    width: 200px;
    height: 50px;
  }
}

.linkedInLink {
  text-decoration: underline;

  font-size: 16px;
  text-align: start;
  color: white;

  font-weight: 200;
}

.linkedInLink:hover {
  opacity: 0.7;
}

.spacer {
  height: 75px;
}

.influencer_types {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin: 100px;
}

.influencer_up {
  position: relative;
  width: 20%;
  height: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
}

.influencer_down {
  position: relative;
  width: 20%;
  height: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
  margin-top: 50px;
  border-radius: 10px;
}

.overlay_div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* optional: center content */
  display: flex;
  justify-content: start;
  align-items: end;
  /* optional: background if needed */
  /* background-color: rgba(0, 0, 0, 0.5);  */
}

.center_overlay_div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* optional: center content */
  display: flex;
  justify-content: center;
  align-items: center;
  /* optional: background if needed */
  /* background-color: rgba(0, 0, 0, 0.5);  */
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.tag_text {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  color: rgba(0, 88, 220, 1);
}

.influencer_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.focus_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 200px;
  background-color: rgba(0, 88, 220, 0.05);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important;
}

.focus_button {
  position: relative;
  width: 300px;
  height: 90px;
  margin-right: 60px;
}

.focus_button_image {
  width: 100%;
  height: 100%;
}

.focus_text {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 50%;
  margin-left: 60px;
}

.focus_button_text {
  color: white;
  font-size: 27px;
}

.focus_text_title {
  margin: 0;
  font-size: 30px;
  color: black;
  margin-bottom: 20px;
}
.focus_text_subtitle {
  margin: 0;
  font-size: 18px;
  color: black;
  font-weight: 300;
  text-align: center;
  text-align: left;
}

.influencer_types_title {
  margin: 0;
  font-size: 40px;
  font-weight: 300;
}

.influencer_types_subtitle {
  margin: 0;
  margin-top: 7.5px;
  font-size: 25px;
  font-weight: 300;
  opacity: 0.7;
}

.explore_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0058dc;
  color: white;
  width: 150px;
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
}

.what_is_text {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 50%;
}

.what_is_title {
  margin: 0;
  font-weight: 300;
  font-size: 50px;
  margin-bottom: 15px;
}

.what_is_subtitle {
  text-align: start;
  margin: 0;
  font-weight: 300;
  font-size: 30px;
}

.influence_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 300px;
  margin-top: 75px;
  margin-bottom: 30px;
}

.influence_image {
  /* width: 40%; */
  height: 100%;
  object-fit: cover;
}

.benefit_cards {
  margin-top: 75px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-items: center;
  align-items: center;
  width: 90%;
  /* margin-left: 1000px;
  margin-right: 1000px; */
}

.benefit_card {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 280px;
  height: 320px;
  background-color: white;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important; */
  box-sizing: border-box;
  /* padding: 15px; */
  /* padding: 50px; */
}

.benefit_image {
  /* width: 100%; */
  height: 45%;
  object-fit: cover;
}

.benefit_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
}
.benefit_title {
  margin: 0;
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 5px;
  color: black;
}
.benefit_subtitle {
  margin: 0;
  margin-top: 5px;
  font-size: 18px;
  /* text-align: start; */
  flex-grow: 1;
  font-weight: 300;
  text-align: center;
}

.marketer_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  margin-top: 100px;
  margin-bottom: 40px;
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 88, 220, 1),
    rgba(0, 88, 220, 0.6)
  );
}

.marketer_title {
  margin: 0;
  font-size: 40px;
  color: white;
  margin-bottom: 20px;
}
.marketer_subtitle {
  margin: 0;
  font-size: 24px;
  color: white;
  font-weight: 300;
  text-align: center;
  width: 70%;
}

.bottom_background {
  /* width: 80%; */
  width: 90%;
  height: 450px;
  object-fit: cover;
  border-radius: 30px;
  /* margin-bottom: 60px; */
}

.bottom_content_text {
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  align-items: start;
}

.bottom_content_image {
  height: 350px;
  margin-right: 220px;
}

.bottom_content_title {
  color: white;
  font-size: 45px;
  text-align: left;
  width: 70%;
}

.bottom_content_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 80px;
  border: 2px solid white;
  border-radius: 50px;
  color: white;
  font-size: 22px;
}

.ai_title {
  color: black;
  font-size: 40px;
  font-weight: 300;
  margin: 0;
  opacity: 0.75;
}

.ai_props {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-items: center;
  align-items: center;
  width: 90%;
  margin-bottom: 40px;
}

.ai_card {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 380px;
  height: 380px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
  box-sizing: border-box;
  /* padding: 15px; */
  /* padding: 50px; */
}

.ai_prop_image {
  width: 100%;
  height: 45%;
  object-fit: cover;
}
