.main_layout {
  display: flex;
  flex-direction: column;
  padding: 34px;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 10px;
}

.page_subtitle {
  align-self: start;
  font-size: 22px;
  font-weight: 500;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.feedbackBox {
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  padding: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 300;
  resize: none;
}

.send_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--color-scheme-hex);
  color: white;
  align-self: end;
  cursor: pointer;
}

.send_button:hover {
  opacity: 0.75;
}
