.AI_button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: none;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 300%;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.AI_button:hover {
  animation: ani 4s linear infinite;
  border: none;
}

@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

.AI_button:before {
  display: flex;
  align-items: center;
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  /* border: 1px solid #03a9f4; */
  background-size: 300%;
  border-radius: 5px;
  transition: 1s;
}

.AI_button:hover::before {
  filter: blur(10px);
}

.AI_button:active {
  background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
}

.ai_options {
  position: absolute;
  bottom: calc(100% + 20px);
  right: 0;
  width: 200px;
  z-index: 99999999999;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  background-color: white;
}

.ai_option_item {
  display: flex;
  align-items: center;
  height: 50px;
  color: black;
  font-size: 14px;
  font-weight: 300;
  box-sizing: border-box;
  padding: 10px;
  opacity: 0.7;
  color: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
}

.ai_option_item:hover {
  background: rgba(0, 88, 220, 0.1);
}

.loading_message {
  border-radius: 5px;
  /* background-color: rgba(44, 44, 44, 0.1); */
  padding: 10px;
  color: white;
  font-size: 14px;
  margin: 0;
  width: 100%;
}

.loader {
  display: block;
  --height-of-loader: 4px;
  --loader-color: white;
  width: 100%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 2s ease-in-out infinite;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}
