.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 230px;
  background-image: linear-gradient(
    to right,
    rgba(0, 88, 220, 0.6),
    rgba(0, 88, 220, 1)
  );
  box-sizing: border-box;
  padding: 30px;
}

.footer_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 100%;
}

.footer_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 70%;
}

.footer_title {
  margin: 0;
  font-size: 26px;
  color: white;
}

.footer_addy_box {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  align-items: start;
}

.footer_addy {
  margin: 0;
  font-size: 13px;
  color: white;
  opacity: 0.6;
}

.footer_right_top {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
}

.footer_right_bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.footer_link_layout {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 140px;
  height: 100px;
}

.email_signup {
  display: flex;
  flex-direction: column;

  width: 400px;
  height: 100px;

  /* background-color: white; */
}

.weekly_title {
  color: white;
  align-self: start;
  margin: 0;
  margin-bottom: 25px;
}

.email_text {
  margin: 0;
  color: white;
  margin-top: 10px;
  font-size: 14px;
}

.email_input_layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.copyright_text {
  margin: 0;
  color: white;
  opacity: 0.6;
  font-size: 15px;
  margin-top: 7.5px;
}

.subscribe_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;
}

.subscribe_button_text {
  color: #8b2222;
}

.email_input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 3px;
  color: white;
}

.links_title {
  color: white;
  margin: 0;
  font-size: 20px;
  text-decoration: underline;
}

.links {
  color: white;
  text-align: start;
  margin: 0;
  font-weight: 200;
  font-size: 15px;
  margin-top: 15px;
  cursor: pointer;
}

.links:hover {
  opacity: 0.7;
}

.footer_mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 88, 220, 0.6),
    rgba(0, 88, 220, 1)
  );

  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 35px;
}

.footer_mobile_title {
  margin: 0;
  color: white;
  font-size: 28px;
  font-weight: 200;
  margin-bottom: 5px;
}
.footer_mobile_email {
  color: white;
  margin: 0;
  margin-bottom: 30px;
}
.footer_mobile_links {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer_mobile_link {
  margin: 0;
  color: white;
  margin-bottom: 10px;
  font-weight: 300;
}

.footer_mobile_newsletter_layout {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 110px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 15px;
  margin-bottom: 15px;
}
.footer_mobile_newsletter_title {
  color: white;
  margin: 0;
  font-size: 20px;
}

.footer_mobile_newsletter_email_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
}

.mobile_email_input {
  flex-grow: 1;
  height: 70%;
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
}
.mobile_email_bt {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 61, 130, 0.3);
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
  height: 90%;
  border-radius: 5px;
}
.mobile_email_bt_text {
  color: white;
  font-size: 13px;
}

.footer_mobile_addy {
  color: white;
  margin: 0;
  width: 50%;
  margin-bottom: 12.5px;
  opacity: 0.5;
}

.footer_mobile_copyright {
  color: white;
  margin: 0;
  font-size: 9px;
  opacity: 0.9;
}

.mobile_email_input::placeholder {
  color: white;
  opacity: 0.6;
}
