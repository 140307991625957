.main_layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.02);
}

.inbox_header {
  display: flex;
  height: 170px;
  width: 100%;
  background-color: white;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
  z-index: 99995;
}
.page_title {
  display: flex;
  color: black;
  font-size: 30px;
  align-items: start;
  font-weight: 300;
  margin: 0;
  margin-bottom: 0px;
}

.header_content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.inbox_header_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  flex: 1;
  z-index: 99999;
  box-sizing: border-box;
  padding: 24px;
}

.mailbox_title {
  margin: 0;
  text-align: start;
  align-self: start;
}

.inboxes_sidebar_content {
  display: flex;
  flex-direction: column;
  /* margin-top: 10px; */
  gap: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 5px;
}

.header_content_title {
  margin: 0;
}

.header_content_subtitle {
  margin: 0;
  font-weight: 200;
  opacity: 0.7;
  margin-top: 5px;
}

.tabs {
  display: flex;
  align-items: start;
  justify-content: start;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  margin-left: 20px;
}
/* background: rgba(255, 255, 255, 0.15); */

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 300;
  color: black;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-align: start;
  margin-bottom: 15px;
  gap: 14px;
}

.tab_disconnected {
  /* background-color: rgba(211, 84, 0, 1); */
  color: rgba(211, 84, 0, 1);
}

.active_disconnected {
  color: rgba(211, 84, 0, 1);
  background-color: rgba(211, 84, 0, 0.075);
  border-radius: 3px;
}

.tab:not(:last-child) {
  margin-right: 15px;
}

.tab:hover {
  color: rgba(0, 88, 220, 0.5);
}

.active {
  color: rgba(0, 88, 220, 1);
  background-color: rgba(0, 88, 220, 0.05);
  border-radius: 3px;
}

.underline {
  position: absolute;
  bottom: 0;
  height: 1px;
  background: rgba(0, 88, 220, 1);
  transition: left 0.3s ease, width 0.3s ease;
}

.inbox_content {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  overflow: hidden;
}

.no_chat_selected {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}

.select_chat_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.075); */
  width: 250px;
  height: 100px;
  border-radius: 5px;
}
.select_chat_text {
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
  margin-bottom: 7.5px;
}
.select_chat_image {
  width: 30px;
  height: 30px;
}

.inboxes_sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  height: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(255, 255, 255, 1);
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(255, 255, 255, 1);
}

.search_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.search_input {
  flex-grow: 1;
  height: 50px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 2.5px;
  height: 35px;
  outline: 1px solid rgba(0, 0, 0, 0.15);
  color: black;
  border: none;
  margin-right: 15px;
}

.create_email {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  outline: 1px solid rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 2.5px;
  cursor: pointer;
}

.create_email:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.search_input::placeholder {
  opacity: 0.5;
}
.chat_channel_box {
  max-height: calc(100vh);
  overflow-y: auto;
}

.add_test_prospect {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(44, 78, 133, 1);
  width: 35px;
  height: 35px;
  margin-left: 10px;
  border-radius: 2.5px;
  cursor: pointer;
}

.add_test_prospect:hover {
  background-color: rgba(44, 78, 133, 0.7);
}

.add_image {
  width: 20px;
  height: 20px;
  opacity: 1;
}

.chat_list {
  overflow: auto;
  height: calc(100%);
  width: 100%;
  flex: 1;
}

.chat_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  flex-grow: 1;
  height: calc(100vh - 170px);
}

.chat_layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.chat_layout_content_info {
  display: flex;
  flex-direction: column;

  /* height: calc(100vh - 170px); */
}

.chat_header {
  display: flex;
  align-items: center;
  /* padding: 1rem; */
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px;
  background-color: white;
  position: relative;
  top: 0;
  z-index: 10;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  /* background-color: rgba(0, 0, 0, 0.2) !important; */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4) !important; */
}

.subjectLine {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.chat_avatar {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #6b7280;
  border-radius: 50%;
}

.chat_name {
  margin-left: 1rem;
  color: black;
  font-weight: bold;
}

.chat_messages {
  /* padding: 1.5rem; */
  box-sizing: border-box;
  padding: 24px;
  /* TECH DEBT - message height */
  /* max-height: calc(100% - 50px); */
  flex: 1;
  width: calc(100vw - 275px - 300px - 220px);
  overflow-y: auto;
  /* height: 400px; */
  /* width: 500px; */
  max-height: 400px;
  overflow: auto;
}

.chat_input {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 90px; */
}

/*MESSAGE INPUT */
.message_input_container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  /* align-items: center; */
  /* background-color: #0c0b0b; */
  /* padding: 0.5rem; */
  /* margin-top: 0.5rem; */
  background-color: white;
  /* border: 1px solid rgba(44, 78, 133, 0.1); */
  border-radius: 3px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.3) !important;
  width: 100%;
  box-sizing: border-box;
  /* margin: 24px;
  padding: 15px; */
  padding: 15px;
  width: 96%;
  margin-bottom: 10px;
}

.message_input {
  /* flex-grow: 1; */
  /* padding: 0.5rem; */
  display: flex;
  align-items: start;
  justify-content: start;
  outline: none;
  border: none;
  color: black;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  /* background-color: #0c0b0b; */
}

.message_input_controls {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.message_input_controls_left {
}

.discard_button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.125);
  border: none;
  color: black;
  cursor: pointer;
}

.discard_button:hover {
}

.ai_button {
  width: 150px;
  height: 33px;
  border: none;
  box-sizing: border-box;
}

.send_button_active {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 25px;
  height: 35px;
  border-radius: 5px;
  font-size: 14px;
  background-color: rgba(0, 88, 220, 1);
  color: white;
  cursor: pointer;
}

.send_button_active:hover {
  background-color: rgba(0, 88, 220 0.9);
}

.send_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  background-color: rgba(0, 88, 220, 1);
  border: none;
  color: white;
  opacity: 0.3;
}

/* CHAT CHANNELS */
.chat_card_box {
  position: relative;
  /* height: 100px; */
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.unread_indicator {
  position: absolute;
  top: 20px;
  left: 0;
  width: 2px;
  height: 35px;
  background-color: rgba(0, 88, 220, 1);
}

.chat_card {
  padding: 15px;
  box-sizing: border-box;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  height: 135px;
  /* height: 130px; */
}

.trail_channel_content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  /* overflow: hidden; */
  height: 100%;
  width: 100%;
}

.trail_from {
  display: flex;
  align-items: center;
  margin: 0;
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  gap: 12px;
}

.user_image_bg {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.user_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.trail_subject {
  margin: 0;
  text-align: start;
  font-size: 15px;
  font-weight: 300;
  margin-top: 3px;
}

.trail_preview {
  margin: 0;
  text-align: start;
  font-size: 14px;
  font-weight: 200;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat_card:hover {
  background-color: rgba(0, 88, 220, 0.2);
}

.selected_chat {
  background-color: rgba(0, 88, 220, 0.4);
  border-top: 1px solid rgba(0, 88, 220, 1);
  border-bottom: 1px solid rgba(0, 88, 220, 1);
}

.non_accepted_chat {
  filter: blur(3px);
}

.chat_user_info {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 12.5px;
  width: 100%;
}

.chat_user_info_left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat_time_stamp_text {
  margin: 0;
  font-size: 12px;
  font-weight: 300;
}

.chat_user_avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #d1d5db;
  margin-right: 0.5rem;
}

.chat_user_avatar_image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.user_info_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat_user_name {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  text-align: start;
  margin: 0;
}

.chat_user_name_sub {
  color: #000000;
  font-weight: 300;
  font-size: 13px;
  text-align: start;
  margin: 0;
}

.chat_user_role {
  color: #000000;
  font-weight: 200;
  font-size: 13px;
  text-align: start;
  margin: 0;
}

.chat_time {
  margin: 0;
}

.unread_messages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  background-color: #2ebb55;
}

.chat_time_box {
  height: 10px;
}

.more_icon {
  display: flex;
  flex-direction: row;
  height: 10px;
  width: 20px;
  align-items: center;
  justify-content: space-between;
}

.more_circle {
  height: 4px;
  width: 4px;
  border-radius: 100px;
  background-color: white;
}

.chat_recent_message {
  color: white;
  text-align: start;
  overflow: hidden;
}

.chat_message {
  height: 35px;
  overflow: hidden;
  font-size: 14px;
  color: black;
  margin: 0;
}

.chat_conversatsion_details {
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  color: black;
  opacity: 0.65;
  user-select: none;
}

.chat_follow_up_update {
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  color: rgba(44, 78, 133, 1);
  opacity: 1;
  user-select: none;
}

.chat_tags {
  display: flex;
  height: 1.5rem;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  color: #6b7280;
}

.high_interest {
  background-color: #fef3c7;
}

.low_interest {
  background-color: #fed7d7;
}

.divider {
  height: 0.0625rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.message_container {
  display: flex;
  margin-bottom: 1rem;
}

.reversed {
  flex-direction: row-reverse;
}

.message_avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  align-self: start;
}

.message_avatar_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.message_avatar_image_icon {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  object-fit: contain;
  opacity: 0.5;
}

.message_avatar_other {
  margin-right: 0.5rem;
}

.message_avatar_self {
  margin-left: 0.5rem;
}

.message_info {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 40px;
}

.self_message_info {
  flex-direction: row-reverse;
}

.message_content {
  text-align: left;
  max-width: 70%;
  word-wrap: break-word;
}

.self_message {
  flex-direction: row;
}

.other_message {
  flex-direction: row-reverse;
}

.message_text_content {
  border-radius: 5px;
  background-color: rgba(44, 78, 133, 1);
  padding: 10px;
}

.message_text_content_other {
  border-radius: 5px;
  background-color: rgba(44, 78, 133, 1);
  padding: 10px;
}

.input_required {
  border-radius: 5px;
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 14px;
  margin: 0;
}

.end_conversation {
  border-radius: 5px;
  /* background-color: #2c2c2c; */
  border: 1px solid red;
  /* color: red; */
  color: red;
  padding: 10px;
  font-size: 14px;
  margin: 0;
}

.self_message_content {
  /* background-color: #2ebb55; */
}

.other_message_content {
  /* display: flex;
      flex-direction: rpw;
      flex-direction: row-reverse; */
}

/* .other-message {
      flex-direction: row-reverse;
  } */

.message_sender {
  margin: 0;
  color: black;
}

.message_time {
  font-size: 0.75rem;
  color: #6b7280;
  margin: 0;
}

.message_text {
  font-size: 14px;
  margin: 0;
}

.search_filter_options {
  display: flex;
  flex-direction: row;
  align-self: self-start;
  margin-left: 5px;
  margin-bottom: 5px;
}

.search_filter {
  /* padding: 5px; */
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2.5px;
  margin-right: 10px;
}

.search_filter_text {
  margin: 0;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

/* 
  Inbox Side Profile */

.self_message_text_content {
  background-color: #003d82;
}
.other_message_text_content {
  background-color: #2c2c2c;
}

.side_profile_component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3) !important;
  background-color: white;
  box-sizing: border-box;
  padding: 15px;
}

.side_profile_component:hover {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4) !important;
}

.side_profile_image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 80px;
  margin-top: 15px;
  border: 1px solid rgba(0, 61, 130, 1);
}

.side_profile_top_info {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 3px;
}

.side_profile_top_left_info {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.side_profile_top_right_info {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.side_profile_name {
  color: black;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
}

.side_profile_subtitle {
  color: black;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.8;
}

.biography {
  color: black;
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  opacity: 0.8;
  max-height: 220px;
  overflow: scroll;
}

.side_profile_tag {
  color: white;
  margin: 0;
  font-weight: 300;
  font-size: 13px;
  padding: 5px;
}

.side_profile_tag_box {
  background-color: #00820f;
  border-radius: 2.5px;
}

.side_profile_email {
  color: black;
  margin: 0;
  font-size: 10px;
}

.side_profile_phone {
  color: black;
  margin: 0;
  font-size: 10px;
}

.linkedin_profile_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0077b5;
  width: 90%;
  height: 40px;
}

.linkedin_profile_text {
  color: white;
}

.lineDivider {
  /* background-color: rgba(0, 0, 0, 0.4); */
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.side_profile_section {
  width: 90%;
}

.side_profile_section_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.side_profile_section_title {
  margin: 0;
}

.side_profile_section_edit {
  margin: 0;
  font-size: 13px;
}

.side_profile_section_content {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  /* background-color: #003d82; */
  background-color: rgba(0, 61, 130, 0.1);
  margin-top: 10px;
  height: 40px;
  border-radius: 5px;
}

.side_profile_section_text {
  margin: 0;
  margin-left: 10px;
  color: #003d82;
}

.side_profile_notes_section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 90%;
}

.side_profile_notes_section_content {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  /* background-color: #003d82; */
  background-color: rgba(0, 61, 130, 0.1);
  margin-top: 10px;
  height: 100%;
  border-radius: 5px;
}

.side_profile_section_notes {
  margin: 0;
  margin-top: 10px;
  margin-left: 10px;
  color: #003d82;
}

.bottomlineDivider {
  /* background-color: rgba(0, 0, 0, 0.4); */
  /* background-color: rgba(0, 61, 130, 0.4); */
  width: 90%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add_to_data_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 61, 130, 0.1);
  color: rgba(0, 61, 130, 1);
  border: 1px solid rgba(0, 61, 130, 1);
  box-sizing: border-box;
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  font-size: 14px;
}

.add_to_data_button:hover {
  background-color: rgba(0, 61, 130, 0.5);
}

.reset_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(200, 0, 0, 1);
  width: 175px;
  height: 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-right: 15px;
}

.reset_button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.message_input_controls_right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message_input_controls_left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  position: relative;
  height: 1px;
  width: 250px;
}

.blueLine {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background-color: rgba(0, 61, 130, 1);
  width: 10%; /* or any desired width of the line */
  animation: slideBackAndForth 1s infinite; /* 2s is the duration, adjust as needed */
}

@keyframes slideBackAndForth {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 90%; /* Adjusted to 90% to account for the line's 10% width */
  }
}

.emoji_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 20px;
}

.emoji_icon:hover {
  opacity: 1;
}

.emoji_picker_container {
  position: absolute;
  bottom: 105%; /* Adjust as necessary, positions above the toggle */
  right: 0; /* Adjust as necessary */
  z-index: 1000; /* Ensure it's above other elements */
}

.message_spacer {
  /* width: 45px; */
}

.message_layout_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.message_layout_content_reversed {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}

.trash_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 50%;
}

.trash_icon:hover {
  background-color: rgba(200, 0, 0, 0.1); /* Style for trash_icon on hover */
}

.trash_icon:hover .trash_icon_fill {
  fill: rgba(255, 0, 0, 0.75); /* Style for trash_icon_fill on hover */
}

.star_icon {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 30px;
  min-height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 50%;
  /* transition: all 0.1s ease; */
}

.star_icon_filled {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 30px;
  min-height: 30px;
  background-color: rgba(0, 61, 130, 0.1);
  cursor: pointer;
  border-radius: 50%;
  /* transition: all 0.1s ease; */
}

/* .star_icon_clicked {
  justify-content: start;
  padding-left: 10px;
  padding-right: 7px;
  box-sizing: border-box;
  min-width: 75px;
  min-height: 30px;
  border-radius: 5px;
} */

.star_svg {
  stroke: rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}

.star_svg_filled {
  fill: rgba(0, 61, 130, 1);
  stroke: rgba(0, 61, 130, 1);
  opacity: 0.75;
}

.star_icon:hover {
  background-color: rgba(0, 61, 130, 0.1);
}

.star_icon:hover .star_svg {
  stroke: rgba(0, 61, 130, 0.75);
  opacity: 0.75;
}

.rating_input_text {
  margin: 0;
  color: rgba(0, 61, 130, 0.5);
  font-size: 12px;
  user-select: none;
}

.rating_input {
  max-height: 30px;
  width: 40px;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  margin-left: 10px;
  margin-left: 10px;
  padding: 3px;
}

.loading_message {
  border-radius: 5px;
  background-color: rgba(44, 44, 44, 0.1);
  padding: 10px;
  color: white;
  font-size: 14px;
  margin: 0;
}

.loader {
  display: block;
  --height-of-loader: 4px;
  --loader-color: white;
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 2s ease-in-out infinite;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

.pagination_controls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-grow: 1;
  min-height: 35px;
  max-height: 35px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pagination_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  flex-grow: 1;
  width: 100%;
  cursor: pointer;
}

.pagination_button:hover {
  flex-grow: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.75);
}

.line {
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.loading_line_background {
  width: 100%;
  height: 1px;
  overflow: hidden;
  position: relative;
}

.loading_line {
  width: 18px;
  height: 1px;
  background-color: rgba(0, 61, 130, 1);
  position: absolute;
  left: 0;
  animation: bounce 1.75s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: calc(
      100% - 10px
    ); /* 100% of container width minus the width of the line */
  }
}

@keyframes glow {
  0% {
    color: inherit; /* Use the default color */
  }
  50% {
    color: orange; /* Change the color to orange */
  }
  100% {
    color: inherit; /* Restore the default color */
  }
}

.text_glow {
  animation: glow 1s ease-in-out; /* Apply the animation */
}

.message_spacer {
  height: 5px;
}

.side_profile_info {
  /* width: 100%;
  height: 200px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3); */
  width: 100%;
  box-shadow: 0px 0px 2.75px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  box-sizing: border-box;
  padding: 0 15px;
  background-color: rgba(44, 78, 133, 0.04);
  cursor: pointer;
}

.side_profile_info:hover {
  background-color: rgba(44, 78, 133, 0.08);
  box-shadow: 0px 0px 3.25px rgba(0, 0, 0, 0.4);
}

.sideProfileSection {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.side_info_title {
  color: black;
  margin: 0;
  font-size: 13px;
  font-weight: 200;
  opacity: 0.7;
  margin-bottom: 1.5px;
}

.side_info_subtitle {
  color: black;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}

.side_spacer {
  height: 10px;
}

.empty {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.empty_image {
  width: 30px;
  height: 30px;
  opacity: 0.2;
}

.empty_text {
  color: black;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.2;
}

.chat_info {
  position: relative;
}

.unread_side {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  right: 0;
}

.unread_bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(44, 78, 133, 1);
}

.unread_messages_text {
  color: white;
  margin: 0;
  font-size: 12px;
}

.loading_line_background {
  width: 100%;
  height: 2px;
  overflow: hidden;
  position: relative;
}

.loading_line {
  width: 18px;
  height: 2px;
  background-color: rgba(0, 61, 130, 1);
  position: absolute;
  left: 0;
  animation: bounce 1.75s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: calc(
      100% - 10px
    ); /* 100% of container width minus the width of the line */
  }
}

.inbox_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 3px;
}

.header_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-top: 24px;
}

.header_dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 155px;
  height: 34px;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0px 14px;
  cursor: pointer;
}
.header_dropdown:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.dropdown_title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.down_icon {
  width: 15px;
  height: 15px;
  opacity: 0.75;
  user-select: none;
}

.clear_filters {
  margin: 0;
  font-size: 14px;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.3);
}

.clear_filters {
  margin: 0;
  font-size: 14px;
  margin-left: 12px;
  color: rgba(0, 88, 220, 1);
  cursor: pointer;
}

.clear_filters:hover {
  opacity: 0.65;
}

.last_updated {
  margin-right: 24px;
  font-size: 14px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.message_wrapper {
  flex: 1;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-right: 15px;
}

.action_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.action_button:hover {
  opacity: 0.5;
}

.action_text {
  color: black;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.message_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 35px;
}

.message_divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 15px 0;
}

.message_background {
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 3px;
}

.account_info {
  display: flex;
  flex-direction: row;
}

.account_image {
  background-color: rgba(0, 0, 0, 0.06);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.account_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1px;
}

.account_name {
  color: black;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.account_email {
  color: black;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.rich_text {
  background: black;
}

.editor_background {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  padding: 30px;
}

.reply_info {
  display: flex;
  flex-direction: row;
  margin-bottom: 12.5px;
  /* background-color: rgba(0, 0, 0, 0.07); */
  background-color: rgba(0, 61, 130, 0.1);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 10px;
}

.replying_to {
  color: black;
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}

.replying_to_email {
  color: black;
  margin: 0;
  font-size: 13px;
  font-weight: 200;
  margin-left: 5px;
}

.message_input_controls_right {
  gap: 10px;
}

.time_stamp_email {
  color: black;
  margin: 0;
  font-size: 12px;
  opacity: 0.6;
  text-align: end;
  margin-top: 25px;
}

.channel_timestamp {
  position: absolute;
  top: 15px;
  right: 15px;
  color: black;
  margin: 0;
  font-size: 12px;
  opacity: 0.6;
  text-align: end;
}

.header_dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown_content {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  width: 100%;
  box-shadow: 0 0px 2.8px rgba(0, 0, 0, 0.28);
  z-index: 1;
  margin-top: 10px;
}

.dropdown_item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  color: black;
  font-size: 14px;
  font-weight: 300;
  height: 45px;
}

.dropdown_item:hover {
  background-color: rgba(0, 88, 220, 0.07);
}

.rotate_180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

.no_mailboxes_connected {
  width: 100%;
  /* height: 100px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  padding: 3px;
}

.connect_mailbox_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 88, 220, 0.07);
  border: 1px solid rgba(0, 88, 220, 0.7);
  color: rgba(0, 88, 220, 1);
  height: 45px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
}

.connect_mailbox_button:hover {
  background-color: rgba(0, 88, 220, 0.15);
}
