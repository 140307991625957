.main_layout {
  display: flex;
  flex-direction: column;

  height: calc(100vh);
  overflow-y: auto;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.dashboard_content {
  display: flex;
  flex-direction: row;
  padding: 24px;
  height: calc(100vh);
  overflow-y: auto;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 24px;
}

.needle_chart {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: end;
  flex: 1;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25) !important;
  max-height: 200px;

  /* margin-right: 25px; */
}

.line_chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  /* flex: 1; */
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25) !important;
  height: 200px;
  padding-top: 20px;
  /* margin-right: 25px; */
}

.page_header {
  width: 100%;
  height: 200px;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.top_row_stats {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 260px;
  margin-top: 24px;
}

.half_width_tile {
  width: 49%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
}

.right_side {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 49%;
  box-sizing: border-box;
}

.top_stats {
  display: flex;
  flex-direction: row;
  height: 200px;
  gap: 25px;
  box-sizing: border-box;
}

.top_stat {
  position: relative;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
  height: 100%;
  width: 100%;
}

.left_side {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
  box-sizing: border-box;
  width: 49%;
}

.stats_two_row {
  display: flex;
  flex-direction: row;
  gap: 25px;
  height: 450px;
  width: 100%;
}

.tall_stat {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
  height: 100%;
  width: 100%;
}

.top_stat_circle {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: start;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
  height: 100%;
  width: 100%;
}

.page_title {
  display: flex;
  color: black;
  font-size: 30px;
  align-items: start;
  font-weight: 300;
  margin: 0;
  padding: 24px;
  padding-bottom: 0px;
}

.tile_information {
  position: absolute;
  top: 14px;
  left: 14px;
}

.tile_title {
  color: black;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.tile_title_large {
  color: black;
  font-size: 24px;
  font-weight: 200;
  margin: 0;
}

.country_chart {
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25) !important;
  /* padding-top: 50px; */
  box-sizing: border-box;
  position: relative;
  /* max-height: 400px; */
}
