.cbx {
    position: relative;
    top: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #c8ccd4;
    border-radius: 3px;
    vertical-align: middle;
    transition: background 0.1s ease;
    cursor: pointer;
    display: inline-block; /* Change display to inline-block */
  }
  
  .cbx:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 9px;
    width: 4px;
    height: 10px;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 1.2px solid #fff;
    border-bottom: 1.2px solid #fff;
    transition: all 0.3s ease;
    transition-delay: 0.15s;
  }
  
  .cbx:checked ~ .cbx {
    border-color: transparent;
    background: rgba(0, 61, 130, 1);
    animation: jelly 0.6s ease;
  }
  
  .cbx:checked ~ .cbx:after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
  
  .cntr {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
  }
  
  @keyframes jelly {
    from {
      transform: scale(1, 1);
    }
  
    30% {
      transform: scale(1.25, 0.75);
    }
  
    40% {
      transform: scale(0.75, 1.25);
    }
  
    50% {
      transform: scale(1.15, 0.85);
    }
  
    65% {
      transform: scale(0.95, 1.05);
    }
  
    75% {
      transform: scale(1.05, 0.95);
    }
  
    to {
      transform: scale(1, 1);
    }
  }
  
  .hidden_xs_up {
    display: none !important;
  }
  
  .lbl {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
  }
  
  .checkbox_label_text {
    margin: 0;
    margin-left: 10px;
    color: black;
    opacity: 0.5;
    text-align: start;
  }
  