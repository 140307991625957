.page_layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
  padding: 24px;
  box-sizing: border-box;
  background-color: white;
}

.settings_title {
  display: flex;
  color: black;
  font-size: 30px;
  align-items: start;
  font-weight: 300;
  margin: 0;
  margin-bottom: 30px;
}

.settings_layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 310px;
  border: none;
  background: white;
  overflow-y: auto;
  height: 100%;

  background-color: white;

  /* border-right: 1px solid rgba(0, 0, 0, 0.5); */
  border-radius: 5px;
  box-sizing: border-box;
}

.divider {
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  margin-right: 20px;
}

.content_layout {
  flex: 1;
  max-height: calc(100vh - 170px);
  overflow: auto;
  /* background-color: blue; */
  /* background-color: white;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-left: 24px;
  box-sizing: border-box;
  padding: 40px; */
}

.menu_item {
  position: relative;
  display: flex;
  flex-direction: row;

  align-items: center;
  height: 60px;
  box-sizing: border-box;
  padding: 15px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  gap: 20px;
}

.menu_item:hover {
  background-color: rgba(0, 88, 220, 0.04);
  /* color: rgba(0, 88, 220, 0.7); */
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.menu_item_selected {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;

  height: 60px;
  background-color: rgba(0, 88, 220, 0.06);
  color: rgba(0, 88, 220, 1);
  user-select: none;
  border-radius: 5px;
  gap: 20px;
}

.icon {
  /* position: absolute;
  right: 15px; */
}

.item_label_text {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.item_label {
  margin: 0;
  font-size: 15px;
}

.item_sublabel {
  margin: 0;
  font-size: 13px;
  margin-top: 3px;
  font-weight: 200;
}

.sign_out {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  color: rgba(200, 0, 0, 1);
  background-color: rgba(200, 0, 0, 0.05);
  font-weight: 300;
  gap: 20px;
  cursor: pointer;
}

.sign_out:hover {
  background-color: rgba(200, 0, 0, 0.1);
}

.notification_dot_orange {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  background-color: rgba(211, 84, 0, 1);
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 300;
}
