.App {
  text-align: center;
}

:root {
  --color-scheme-hex: #0058dc;
  --color-scheme-rgba_alpha_one: rgba(0, 88, 220, 1);
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
