.main_layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  overflow-y: auto;
}

.page_header {
  position: fixed;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  min-height: 190px;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  /* background-color: rgba(0, 88, 220, 0.04); */
  box-sizing: border-box;
  padding: 24px;
}

.page_title {
  display: flex;
  color: black;
  font-size: 30px;
  align-items: start;
  font-weight: 300;
  margin: 0;
}

.campaign_tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 165px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.campaign_tile:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.campaign_tile_left {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 30%;
}

.campaign_tile_titles {
  display: flex;
  align-items: start;
  justify-content: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.campaign_tile_title {
  margin: 0;
  font-size: 22px;
  font-weight: 200;
  text-align: start;
}

.campaign_tile_subtitle {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
  opacity: 0.8;
  text-align: start;
}

.header_content_subtitle {
  margin: 0;
  font-weight: 200;
  opacity: 0.7;
  margin-top: 5px;
}

.campaign_type_slider {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.01);
  padding: 8px 18px;
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  margin-top: 24px;
}

.tab {
  font-size: 14px;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.75);
  padding: 8px 18px;
  cursor: pointer;
}

.tab:hover {
  color: rgba(0, 0, 0, 1);
}

.active {
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  padding: 8px 18px;
  border-radius: 3px;
}

.progress_section {
  width: 100%;
}

.percent_complete_text {
  display: flex;
  color: rgba(0, 88, 220, 1);
  font-size: 14px;
  align-items: start;
  font-weight: 200;
  margin: 0;

  margin-bottom: 12px;
}

.create_campaign_button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 10px 25px;
  background-color: rgba(0, 88, 220, 1);
  color: white;
  font-size: 15px;
  font-weight: 300;
  border-radius: 3.5px;
  cursor: pointer;
  border: 1px solid rgba(0, 88, 220, 0);
  gap: 10px;
}

.create_campaign_button:hover {
  background-color: white;
  color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 1);
}

.campaign_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  box-sizing: border-box;
  padding: 35px;
  padding-top: 30px;
  margin-bottom: 12px;
}

.campaign_list {
  box-sizing: border-box;
  padding: 35px;
  padding-top: 5px;
  overflow: auto;
}

.list_title {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  opacity: 0.65;
}

.right_actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.search_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  width: 190px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 300;
  border-radius: 5px;
}

.search_container_input {
  margin-left: 5px;
  border: none;
  outline: none;
}

.refresh {
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 37px;
  width: 115px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 300;
}

.campaign_tile_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.campaign_tile_middle {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  gap: 20px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.value {
  margin: 0;
  opacity: 0.85;
  font-weight: 400;
  font-size: 16px;
}

.value_title {
  margin: 0;
  opacity: 0.7;
  font-weight: 200;
  font-size: 14px;
}

.divider_line {
  height: 40px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.more_button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  gap: 2.5px;
  cursor: pointer;
}

.more_button:hover {
  /* opacity: 0.6; */
}

.more_dot {
  width: 2.5px;
  height: 2.5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.75);
}

.campaign_tile_top_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.status_active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 85px; */
  height: 33px;
  border-radius: 3px;
  border: 1px solid rgba(0, 131, 10, 1);
  gap: 2.5px;
  box-sizing: border-box;
  padding: 0px 20px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 131, 10, 1);
  gap: 15px;
  background-color: rgba(0, 131, 10, 0.1);
}

.status_paused {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 85px; */
  height: 33px;
  border-radius: 3px;
  border: 1px solid rgba(200, 0, 0, 1);
  gap: 2.5px;
  box-sizing: border-box;
  padding: 0px 20px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(200, 0, 0, 1);
  gap: 15px;
  background-color: rgba(200, 0, 0, 0.1);
}

.status_completed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 85px; */
  height: 33px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 1);
  gap: 2.5px;
  box-sizing: border-box;
  padding: 0px 20px;
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  gap: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.status_dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.control_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 33px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  opacity: 0.75;
  cursor: pointer;
}

.control_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.counter_weight {
}

.progress_line_background {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 88, 220, 0.35);
  box-shadow: 0px 0px 15px 1.5px rgba(0, 88, 220, 0.3);
  border-radius: 10px;
}

.progress_line {
  height: 1px;
  background-color: rgba(0, 88, 220, 1);
  border-radius: 10px;
}

.header_controls {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-top: 24px;
}

.header_dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 155px;
  height: 34px;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0px 14px;
}

.dropdown_title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.down_icon {
  width: 15px;
  height: 15px;
  opacity: 0.75;
}
