.main_layout {
  padding: 24px;
  position: relative;
}

.my_email_settings {
  width: 100%;
  height: 230px;

  /* background-color: rgba(0, 88, 220, 0.04); */
  border-radius: 3px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  box-sizing: border-box;
  padding: 25px;
  padding-top: 10px;
}

.top_section_title {
  text-align: start;
  font-size: 20px;
  font-weight: 200;
  margin: 0;
  margin-bottom: 7px;
}

.top_section_subtitle {
  text-align: start;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  opacity: 0.7;
  margin-top: 5px;
}

.email_settings_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  margin-bottom: 40px;
}

.email_settings_title_area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.title {
}

.line {
  height: 20px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
}

.email_count {
}

.link_email_button {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 35px;
  font-size: 14px;
  background-color: rgba(0, 88, 220, 1);
  color: white;
  border: 1px solid rgba(0, 88, 220, 1);
  border-radius: 2.5px;
  cursor: pointer;
}

.link_email_button:hover {
  background-color: white;
  color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 1);
}

.mailbox_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  margin-top: 50px;
}

.progress_line_background {
  width: 200px;
  height: 3px;
  background-color: rgba(0, 88, 220, 0.35);
  box-shadow: 0px 0px 15px 1.5px rgba(0, 88, 220, 0.2);
  border-radius: 10px;
}

.progress_line {
  height: 3px;
  background-color: rgba(0, 88, 220, 1);
  border-radius: 10px;
}

.connected_mailboxes {
}

.mailbox_info_header {
  display: flex;
  width: 235px;
  font-size: 15px;
  font-weight: 300;
  margin: 0;
  opacity: 0.7;
  margin-right: 25px;
}

.mailbox_stat_title {
  display: flex;
  align-items: center;
  width: 100px;
  font-size: 15px;
  font-weight: 300;
  margin: 0;
  opacity: 0.7;
}

.mailbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 70px;
  /* background-color: rgba(0, 0, 0, 0.03); */
  border-radius: 3px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 25px;
  margin-top: 20px;
}

.mailbox_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  width: 235px;
  margin-right: 25px;
}

.mailbox_icon {
  width: 28px;
  height: 28px;
  box-shadow: 0px 0px 1.5px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 7px;
  border-radius: 5px;
}

.mailbox_title {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.mailbox_stat {
  display: flex;
  align-items: center;
  width: 100px;
  font-size: 15px;
  font-weight: 300;
  margin: 0;
}

.more_button {
  position: absolute;
  right: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  gap: 2.5px;
  cursor: pointer;
}

.more_dot {
  width: 2.5px;
  height: 2.5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.75);
}

.no_mailboxes_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  gap: 20px;

  font-size: 20px;
  font-weight: 200;
}

.connect_first_mailbox_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background-color: rgba(0, 88, 220, 0.07);
  border: 1px solid rgba(0, 88, 220, 0.7);
  color: rgba(0, 88, 220, 1);
  height: 45px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
}

.connect_first_mailbox_button:hover {
  background-color: rgba(0, 88, 220, 0.15);
}

.email_status_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  gap: 15px;
}

.email_status_active {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  gap: 9px;
  color: rgba(0, 131, 10, 1);
}

.email_status_disconnected {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  gap: 9px;
  color: rgba(211, 84, 0, 1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.status_dot_active {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 131, 10, 1);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
}

.status_dot_active::after {
  content: "";
  position: absolute;
  /* top: 50%;
  left: 50%; */
  width: 90%;
  height: 90%;
  border-radius: 50%;
  border: 2px solid rgba(0, 131, 10, 0.5);
  transform: translate(-50%, -50%);
  animation: pulse 1.5s infinite;
}

.status_dot_disconnected {
  background-color: rgba(211, 84, 0, 1);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.reconnect_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 27px;
  background-color: rgba(0, 131, 10, 0.075);
  color: rgba(0, 131, 10, 1);
  border: 1px solid rgba(0, 131, 10, 0.75);
  margin-left: 55px;
  font-size: 13px;
  font-weight: 300;
  border-radius: 5px;
  cursor: pointer;
}

.reconnect_button:hover {
  background-color: rgba(0, 131, 10, 0.15);
}
