.tile_information {
  position: absolute;
  top: 14px;
  left: 14px;
}

.tile_title {
  color: black;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.chart_shadow {
  margin-top: 50px;
}

.opacity {
  opacity: 0.5;
}
