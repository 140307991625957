.frequency_options {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 17px;
}

.frequency_option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.frequency_select {
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.frequency_text {
  margin: 0;
  opacity: 0.65;
  font-size: 14px;
  font-weight: 300;
}

.radio_inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  /* background-color: #eee; */
  box-sizing: border-box;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.3);
  padding: 0.25rem;
  width: 300px;
  font-size: 14px;
}

.radio_inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio_inputs .radio input {
  display: none;
}

.radio_inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
}

.radio_inputs .radio input:checked + .name {
  background-color: rgba(0, 88, 220, 0.04);
  color: rgba(0, 88, 220, 1);
  font-weight: 600;
}
