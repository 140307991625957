.main_layout {
  width: 100%;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 25px;
}

.social_account {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: rgba(0, 88, 220, 0.04);
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 25px;
  margin-bottom: 25px;
}

.social_icon {
  width: 35px;
  height: 35px;
}

.social_title {
  margin-left: 30px;
  font-size: 16px;
  font-weight: 300;
}

.right_side {
  display: flex;
  justify-content: end;
  flex: 1;
}

.connect_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px;
  height: 30px; */
  padding: 5px 15px;
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 13px;
  font-weight: 200;
  cursor: pointer;
}


.connect_button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
}
