.dropdown_menu {
  position: absolute;
  bottom: -30px;
  right: 40px;
  background-color: white; /* red color was for debugging, I assume */
  border: 1px solid #ccc;
  border-radius: 4px;
  opacity: 0;
  transform: scale(0);
  transform-origin: bottom right;
  transition: opacity 0.15s, transform 0.15s;
  user-select: none;
  z-index: 999;
  width: 200px;

  z-index: 999999;
}

.dropdown_menu.open {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

.platform_item {
  display: flex;
  width: 200px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 300;
}

.platform_item:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
