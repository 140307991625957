.legal_layout {
  display: flex;
  flex-direction: column;
  padding: 100px;
  padding-bottom: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  box-sizing: border-box;
}
